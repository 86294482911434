<div class="grid bandbeatPage">

  <div class="col-12">
    <h3>Your Calendar</h3>
  </div>

  <div class="col-12 lg:hidden">

    <button
      *ngIf="!studioCalendarSyncType"
      pButton
      pRipple
      label="Setup sync with Google Calendar"
      icon="pi pi-google"
      class="
        w-full
        p-button-secondary
        secondary-button
        mr-2
        mb-2
      "
      (click)="startGoogleAuth()"
    ></button>

    <div *ngIf="studioCalendarSyncType" class="google-calendar-sync">
      <span class="sync-text">
        Studio in sync with Google Calendar
      </span>
      <i
        class="pi pi-info-circle info-icon"
        pTooltip="View Configuration"
        tooltipPosition="top"
        (click)="showSyncConfiguration()"
      ></i>
      <i
        class="pi pi-sync info-icon"
        tooltipPosition="top"
        style="margin-left:1rem"
        [ngClass]="{ 'i-disabled': isSyncCooldownActive }"
        [pTooltip]="isSyncCooldownActive ? 'Please wait ' + syncCooldownDuration.toString() + ' minutes before syncing again' : 'Sync now'"
        (click)="!isSyncCooldownActive && runCalendarSync(false)"
      ></i>
      <i
        class="pi pi-sign-out info-icon"
        pTooltip="Unlink Google Calendar"
        tooltipPosition="top"
        style="margin-left:1rem"
        (click)="revokeGoogleAuth()"
      ></i>
    </div>

    <button
      pButton
      pRipple
      label="Add Unavailable Time"
      icon="pi pi-google"
      class="
        w-full
        p-button-secondary
        secondary-button
        mr-2
        mb-2
      "
      (click)="showNew(true)"
    ></button>

    <div class="card widget-list">
      <h4>Select Rooms</h4>
      <ng-template ngFor let-child let-i="index" [ngForOf]="availableRooms">

        <div
          style="padding: 12px;"
          class="mb-4 px-3 flex align-items-center justify-content-start">
          <div class="option-title">
            <div class="roomColorBox" style="background:{{child.color}}"></div>
            <span>  {{child.name}} </span>
          </div>
          <div >
            <p-checkbox name="groupname" [value]="child.code" [(ngModel)]="selectedRooms"></p-checkbox>
          </div>
        </div>

      </ng-template>
    </div>

  </div>

  <div class="col-12 lg:col-10">
    <div class="grid">

<!--      <div class="col-12">-->
<!--        <h3>Your Calendar</h3>-->
<!--      </div>-->
      <div class="col-12">
        <full-calendar [options]="(options | filterCalendar: selectedRooms)"></full-calendar>
      </div>
    </div>
  </div>

  <div class="right-panel lg:col-2">

    <button
      *ngIf="!studioCalendarSyncType"
      pButton
      pRipple
      label="Setup sync with Google Calendar"
      icon="pi pi-google"
      class="
        w-full
        p-button-secondary
        secondary-button
        mr-2
        mb-2
      "
      (click)="startGoogleAuth()"
    ></button>

    <div *ngIf="studioCalendarSyncType" class="google-calendar-sync">
      <span class="sync-text">
        Studio in sync with Google Calendar
      </span>
      <i
        class="pi pi-info-circle info-icon"
        pTooltip="View Configuration"
        tooltipPosition="top"
        (click)="showSyncConfiguration()"
      ></i>
      <i
        class="pi pi-sync info-icon"
        tooltipPosition="top"
        style="margin-left:1rem"
        [ngClass]="{ 'i-disabled': isSyncCooldownActive }"
        [pTooltip]="isSyncCooldownActive ? 'Please wait ' + syncCooldownDuration.toString() + ' minutes before syncing again' : 'Sync now'"
        (click)="!isSyncCooldownActive && runCalendarSync(false)"
      ></i>
      <i
        class="pi pi-sign-out info-icon"
        pTooltip="Unlink Google Calendar"
        tooltipPosition="top"
        style="margin-left:1rem"
        (click)="revokeGoogleAuth()"
      ></i>
    </div>

    <button
      pButton
      pRipple
      label="Add Unavailable Time"
      icon="pi pi-plus"
      class="
        w-full
        p-button-secondary
        secondary-button
        mr-2
        mb-2
      "
      (click)="showNew(true)"
    ></button>

    <div class="card widget-list">
      <h4>Rooms</h4>
      <ng-template ngFor let-child let-i="index" [ngForOf]="availableRooms">

        <div
          style="padding: 12px 0 12px 0;"
          class="flex align-items-center justify-content-start">
          <div class="option-title">
            <div class="roomColorBox" style="background:{{child.color}}"></div>
            <span>  {{child.name}} </span>
          </div>
          <div >
            <p-checkbox name="groupname" [value]="child.code" [(ngModel)]="selectedRooms"></p-checkbox>
          </div>
        </div>

      </ng-template>
    </div>

  </div>

  <div class="progress-spinner-container" *ngIf="loading">
    <p-progressSpinner ariaLabel="loading"
                       animationDuration=".5s"
                       strokeWidth="8"
                       class="add-shadow"
    />
  </div>
</div>

<p-dialog
  [(visible)]="offlineBookingDialog"
  [style]="{width: '450px'}"
  header="Add Unavailable Time"
  [modal]="true"
  styleClass="p-fluid">
  <ng-template pTemplate="content">



    <div class="formgrid grid">
      <div class="field col-12 md:col-12">
        <span class=" p-float-label">

          <p-calendar
            id="datetime" dateFormat="dd/mm/yy"
            [(ngModel)]="bookDate"
            (onSelect)="buildAvailabilityOptions()"
            [minDate]="currentDate"
            [maxDate]="maxDate"
            firstDayOfWeek="1"
          ></p-calendar>
          <label for="datetime">Book Date</label>
        </span>
      </div>


      <div class="field col-12 md:col-12">
        <span class=" p-float-label">
          <p-dropdown
            id="bookTime"
            [options]="availabilityOptions"
            [(ngModel)]="bookTime"
            [autoDisplayFirst]="false"
          ></p-dropdown>
          <label for="bookTime">Book Time</label>
        </span>
      </div>


      <div class="field col-12 md:col-12">
        <span class=" p-float-label">
          <p-dropdown
            inputId="room"
            id="room"
            [options]="availableRoomsForAppointment"
            [(ngModel)]="bookRoom"
            optionLabel="name"
            optionValue="code"
          ></p-dropdown>
          <label for="room">Studio Room</label>
        </span>
      </div>


      <div class="field col-12 md:col-12">
        <span class=" p-float-label">
          <p-inputNumber
            id = "duration"
            [(ngModel)]="duration"
            [step]="1"
            [min]="1"
          ></p-inputNumber>
          <label for="duration">Duration</label>
        </span>
        <p-tag
          styleClass="mr-2 infoTag"
          icon="pi pi-info-circle"
        ></p-tag>
<span>Please select a duration up to 6 hours</span>
      </div>

    </div>


  </ng-template>

  <ng-template pTemplate="footer">

    <button
      pButton
      pRipple
      type="button"
      class=" p-button-danger p-button-text"
      label="Cancel"
      (click)="hideDialog()"
    ></button>

    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="addBooking()"></button>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="offlineBookingEditDialog"
  [style]="{width: '450px'}"
  header="Edit Unavailable Time"
  [modal]="true"
  styleClass="p-fluid">
  <ng-template pTemplate="content">



    <div class="formgrid grid">
      <div class="field col-12 md:col-12">
        <span class=" p-float-label">

          <p-calendar
            dateFormat="dd/mm/yy"
            [(ngModel)]="bookDate"
            (onSelect)="buildAvailabilityOptions()"
            [minDate]="currentDate"
            [maxDate]="maxDate"
            firstDayOfWeek="1"
          ></p-calendar>
          <label for="datetime">Book Date</label>
        </span>
      </div>


      <div class="field col-12 md:col-12">
        <span class=" p-float-label">
          <p-dropdown
            [options]="availabilityOptions"
            [(ngModel)]="bookTime"
            [autoDisplayFirst]="false"
          ></p-dropdown>
          <label for="bookTime">Book Time</label>
        </span>
      </div>


      <div class="field col-12 md:col-12">
        <span class=" p-float-label">
          <p-dropdown
            inputId="room"
            [options]="availableRoomsForAppointment"
            [(ngModel)]="bookRoom"
            optionLabel="name"
            optionValue="code"
          ></p-dropdown>
          <label for="room">Studio Room</label>
        </span>
      </div>


      <div class="field col-12 md:col-12">
        <span class=" p-float-label">
          <p-inputNumber
            [(ngModel)]="duration"
            [step]="1"
            [min]="1"
          ></p-inputNumber>
          <label for="duration">Duration</label>
        </span>
        <p-tag
          styleClass="mr-2 infoTag"
          icon="pi pi-info-circle"
        ></p-tag>
        <span>Please select a duration up to 6 hours</span>
      </div>

    </div>


  </ng-template>

  <ng-template pTemplate="footer">

    <button
      pButton
      pRipple
      type="button"
      class=" p-button-danger p-button-text"
      label="Remove"
      icon="pi pi-times"
      (click)="removeOfflineBooking()"
    ></button>

    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="editBooking()"></button>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="bookingDetailDialog"
  [style]="{width: '450px'}"
  header="Booking Details"
  [modal]="true"
  styleClass="p-fluid">

  <app-band-booking-overview
    style = "width: inherit"
    *ngIf="detailedBooking"
    (confirmBookingEvent)="confirmBooking($event.booking,$event.accept)"
    (reportBookingEvent)="reportBooking($event)"
    (newItemEvent)="removeOfflineBooking($event)"
    [detailedBooking] = "detailedBooking"
  ></app-band-booking-overview>

</p-dialog>

<p-dialog
  [(visible)]="calendarSyncWizardDialog"
  [style]="{width: '600px'}"
  header="Google Calendar Sync Setup"
  [modal]="true"
  [closable]="true"
  [dismissableMask]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <!-- Step 1: Calendar Type Selection -->
    <div *ngIf="calendarSyncWizardStep === 1">
      <h3>Sync Setup</h3>
      <p>Select your Google Calendar setup type:</p>
      <div class="field-radiobutton">
        <p-radioButton
          name="calendarType"
          value="multiple"
          label="One Google Calendar per Room"
          [(ngModel)]="selectedCalendarSyncType"
        ></p-radioButton>
        <i
          class="pi pi-info-circle info-icon"
          style="margin-left: 1rem"
          pTooltip="Bandbeat calendar will sync with one Google Calendar. Your Google Calendar set-up must differentiate bookings of different rooms by color."
          tooltipPosition="right"></i>
      </div>
      <div class="field-radiobutton">
          <p-radioButton
            name="calendarType"
            value="single"
            label="Single Google Calendar for All Rooms"
            [(ngModel)]="selectedCalendarSyncType"
          ></p-radioButton>
        <i
          class="pi pi-info-circle info-icon"
          style="margin-left: 1rem"
          pTooltip="Bandbeat calendar will sync with multiple Google Calendars. Each room will be in sync with a specific Google Calendar."
          tooltipPosition="right"></i>
      </div>
    </div>

    <!-- Step 2: Calendar Selection -->
    <div *ngIf="calendarSyncWizardStep === 2">
      <h3>Available Google Calendars</h3>
      <p *ngIf="selectedCalendarSyncType === 'single'">
        Select the Google Calendar to sync across all rooms:
      </p>
      <p *ngIf="selectedCalendarSyncType === 'multiple'">
        Select the Google Calendar to sync against each room:
      </p>

      <div *ngIf="selectedCalendarSyncType === 'single'">
        <p-dropdown
          [options]="availableGoogleCalendars"
          [(ngModel)]="selectedSingleGoogleCalendar"
          optionLabel="summary"
          optionValue="id"
          placeholder="Select a Calendar"
        ></p-dropdown>
      </div>

      <div *ngIf="selectedCalendarSyncType === 'multiple'">
        <div *ngFor="let room of availableRooms">
          <div class="field">
            <label for="{{ 'calendar-' + room.code }}">
              Calendar for {{ room.name }}
            </label>
            <p-dropdown
              [options]="availableGoogleCalendars"
              [(ngModel)]="room.selectedCalendar"
              optionLabel="summary"
              optionValue="id"
              [inputId]="'calendar-' + room.code"
              placeholder="Select a Calendar"
            ></p-dropdown>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 3: Color mapping & Confirmations   -->
    <div *ngIf="calendarSyncWizardStep === 3">
      <h3>Configure Room Mapping</h3>

      <!-- For Single Calendar Setup -->
      <div *ngIf="selectedCalendarSyncType === 'single'">
        <p>Assign a color for each room:</p>
        <div *ngFor="let room of availableRooms">
          <div class="field">
            <label for="{{ 'color-' + room.code }}">{{ room.name }}</label>
            <p-dropdown
              [options]="availableCalendarColors"
              [(ngModel)]="room.googleSyncColor"
              optionLabel="colorId"
              [dataKey]="'colorId'"
              [inputId]="'color-' + room.code"
              placeholder="Select a Color"
            >
              <!-- Template for the dropdown items -->
              <ng-template let-color pTemplate="item">
                <div class="color-item">
                  <div class="color-box" [style.background-color]="color.colorValue"></div>
                  <span *ngIf="color.colorId === '0'"> (Default)</span>
                </div>
              </ng-template>

              <!-- Template for the selected item -->
              <ng-template let-color pTemplate="selectedItem">
                <div class="color-item">
                  <div class="color-box" [style.backgroundColor]="color?.colorValue"></div>
                  <span *ngIf="!color">Select a Color</span>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
      </div>

      <!-- For Multiple Calendars Setup -->
      <div *ngIf="selectedCalendarSyncType === 'multiple'">
        <p>Confirm the calendars mapping for each room:</p>
        <ul style="display:grid">
          <li *ngFor="let room of availableRooms" style="list-style-type:disclosure-closed; margin: .5rem 0; width:75%;">
            <div style="display: flex">
              <div style="flex-grow:1;width:20%;">
                <div class="roomColorBox" [style]="'background:' + room.color"></div>
                <span>{{room.name}}</span>
              </div>
              <div style="flex-grow:1;width:20%;text-align:center">
                <span class="pi pi-sync"></span>
              </div>
              <div style="flex-grow:1;width:20%;">
                <span class="pi pi-google" style="margin-right:1rem"></span>
                <span>{{ room.selectedCalendar ? getCalendarName(room.selectedCalendar) : 'Not Selected' }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="calendarSyncWizardStep === 4">
      <h4>Confirm Sync Setup</h4>

      <ul style="display:grid">
        <li *ngFor="let room of availableRooms" style="list-style-type:disclosure-closed; margin: .5rem 0;">
          <div style="display: flex">
            <div style="flex-grow:1;width:20%;">
              <div class="roomColorBox" [style]="'background:' + room.color"></div>
              <span>{{room.name}}</span>
            </div>
            <div style="flex-grow:1;width:20%;text-align:center">
              <span class="pi pi-sync"></span>
            </div>
            <div style="flex-grow:1;width:40%; display:inline-flex; align-items:center">
              <span class="roomColorCircle" [style]="'background-color:' + room.googleSyncColor.colorValue"></span>
              <span>{{ getCalendarName(selectedSingleGoogleCalendar) }} events</span>
            </div>
          </div>
        </li>
      </ul>

      <div>
        <span>Only the events with the specified colors from your <b>{{ getCalendarName(selectedSingleGoogleCalendar) }}</b> calendar will by synced with your Bandbeat rooms and bookings.</span>
      </div>

    </div>

  </ng-template>

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="Back"
      class="p-button-secondary"
      (click)="previousStep()"
      [disabled]="calendarSyncWizardStep === 1"
    ></button>
    <button
      pButton
      pRipple
      [label]="calendarSyncWizardButtonLabel"
      (click)="nextStep()"
      [disabled]="
        (calendarSyncWizardStep === 1 && !selectedCalendarSyncType) ||
        (calendarSyncWizardStep === 2 && selectedCalendarSyncType === 'single' && !selectedSingleGoogleCalendar) ||
        (calendarSyncWizardStep === 2 && selectedCalendarSyncType === 'multiple' && !areAllRoomsMapped() ||
        (calendarSyncWizardStep === 3 && selectedCalendarSyncType === 'single' && !areAllRoomsColorMapped()))
      "
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  header="Google Calendar Sync Configuration"
  [(visible)]="showSyncConfigDialog"
  [modal]="true"
  [closable]="true"
  [dismissableMask]="true"
  [style]="{ width: '25vw' }"
>
  <div class="sync-configuration-dialog">
    <h3>Sync Type</h3>
    <p *ngIf="studioCalendarSyncType === 'single'">
      <b>Single Calendar</b>: All bookings are synced to a single Google calendar. Bookings at different rooms are differentiated by color in your Google calendar.
    </p>
    <p *ngIf="studioCalendarSyncType === 'multiple'">
      <b>Multiple Calendars</b>: Each room is synced with its own calendar.
    </p>

    <h3>Calendar Details</h3>
    <div *ngIf="studioCalendarSyncType === 'single'">
      <p><b>Calendar ID:</b> {{ availableRooms[0].calendarId }}</p>
      <div class="room-colors">
        <h4>Room Colors</h4>
        <div *ngFor="let room of availableRooms" class="sync-room-row">
          <div class="sync-room-name">
            <b>{{ room.name }}</b>:
          </div>
          <div class="color-box" [style.backgroundColor]="room.eventColor || '#ccc'"></div>
        </div>
      </div>
    </div>

    <div *ngIf="studioCalendarSyncType === 'multiple'">
      <div *ngFor="let room of availableRooms">
        <p>
          <b>{{ room.name }}</b>: {{ room.calendarId || 'No Calendar Assigned' }}
        </p>
      </div>
    </div>

    <div class="dialog-actions">
      <button pButton label="Edit Sync Settings" icon="pi pi-cog" (click)="editSyncSettings()"></button>
      <button pButton label="Close" icon="pi pi-times" class="p-button-secondary" (click)="closeSyncConfiguration()"></button>
    </div>
  </div>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
