<div class="grid bandbeatPage" *ngIf="studio | async as studioData">


  <div class="col-12" style="text-align:right">
    <img
      [src]="studioData.cover"
      class="cover-image"
      style=""
    />
    <button pButton
            id="coverButton"
            pRipple type="button" icon="edit"
            (click)="coverUpload.click()"
            class="p-button-rounded material-icons-outlined uploadButton hidden-mobile">
      <div class="material-icons-outlined">edit</div>
    </button>

    <input
      #coverUpload
      hidden
      type="file"
      (change)="fileSelected($event)"
    />

  </div>
  <div class="col-8">
    <p-avatar shape="circle"
              [image]="studioData.logo ? studioData.logo : 'assets/images/sample_member.png'">
    </p-avatar>
    <button pButton
            id="logoButton"
            pRipple type="button" icon="edit"
            (click)="logoUpload.choose()"
            class="p-button-rounded p-button-icon-only material-icons-outlined uploadButton hidden-mobile">
      <div class="material-icons-outlined">edit</div>
    </button>

    <p-fileUpload
      #logoUpload
      class="logoUploader hidden-mobile"
      [customUpload]="true"
      [auto]="true"
      (uploadHandler)="imageUpload($event, 'logo' , 'studio')"
    ></p-fileUpload>
  </div>

  <div class="col-2 hidden-mobile">
    <button
      *ngIf="studioData.studioImages && studioData.studioImages.length > 0"
      icon="pi pi-images"
      iconPos="left"
      pButton
      pRipple
      type="button"
      label="REMOVE PHOTOS"
      class="p-button-danger viewMore"
      (click)="studioImagesDialog = true"
    ></button>

  </div>

  <div class="col-2 hidden-mobile">
    <button
      icon="pi pi-images"
      iconPos="left"
      pButton
      pRipple
      type="button"
      label="ADD MORE PHOTOS"
      class="p-button-secondary viewMore secondary-button"
      (click)="mediaUpload.choose()"
    ></button>
    <p-fileUpload
      #mediaUpload
      class="logoUploader"
      [customUpload]="true"
      [auto]="true"
      (uploadHandler)="imageUpload($event, 'media' , 'studio')"
    ></p-fileUpload>

  </div>


  <div class="col-12">
    <div class="formgrid grid">
      <div class="field col-12 lg:col-3 ">
        <label for="studioName">Studio Name</label>
        <input inputTrim
          id="studioName"
          [(ngModel)]="studioData.name"
          type="text"
          pInputText
          class="w-full">
      </div>
      <div class="field col-12 lg:col-3">
        <label for="contactEmail">E-mail</label>
        <input inputTrim
          [(ngModel)]="studioData.email"
          pInputText
          id="contactEmail" type="text"
          class="w-full">
      </div>

      <div class="field col-12 lg:col-3">
        <label for="facebookProfile">Facebook</label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon p-disabled p-component:disabled"
                style="min-width: unset; border-bottom: 1px solid #E6E1E5 !important;">https://www.facebook.com/</span>
          <input style="border-radius: 0 4px 0 0 !important;" inputTrim
                 [(ngModel)]="studioData.facebookHandle"
                 pInputText
                 id="facebookProfile" type="text"
                 class="w-full">
        </div>
      </div>

      <div class="field col-12 lg:col-3">
        <label for="instagramProfile">Instagram</label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon p-disabled p-component:disabled"
                style="min-width: unset; border-bottom: 1px solid #E6E1E5 !important;">https://www.instagram.com/</span>
          <input style="border-radius: 0 4px 0 0 !important;" inputTrim
                 [(ngModel)]="studioData.instagramHandle"
                 pInputText
                 id="instagramProfile" type="text"
                 class="w-full">
        </div>
      </div>
    </div>

    <div class="formgrid grid">

      <div class="col-6">

        <div class="formgrid grid">

          <div class="field col-12">
            <label for="addressField">Address</label>
            <input
                    #addressField
                    [(ngModel)]="streetAddress"
                    pInputText
                    id="addressField" type="text" class="w-full">
          </div>

          <div class="field col-12">
            <label for="street">Street</label>
            <input inputTrim
                   pInputText
                   [(ngModel)]="studioData.address.street"
                   id="street" type="text" class="w-full">
          </div>

          <div class="field col-12">
            <label for="number">Number</label>
            <input inputTrim
                   pInputText
                   [(ngModel)]="studioData.address.number"
                   id="number" type="text" class="w-full">
          </div>

          <div class="field col-12 ">
            <label for="zip">Zip Code</label>
            <input inputTrim
                   pInputText
                   [(ngModel)]="studioData.address.zip"
                   id="zip" type="text" class="w-full">
          </div>

          <div class="field col-12">
            <label for="city">City</label>
            <input inputTrim
                   pInputText
                   [(ngModel)]="studioData.address.city"
                   id="city" type="text" class="w-full">
          </div>

          <div class="field col-12">
            <label style="color: #B1EDE8 !important;" for="city">Country</label>
            <p-dropdown
                    inputId="country"
                    id="country"
                    required="true"
                    optionValue="label"
                    [options]="countries"
                    [(ngModel)]="studioData.address.country"
                    (onChange)="selectCountry($event)"
                    placeholder="Select a Country"
            >
              <ng-template let-country pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <div> <span>{{country.emoji}}</span> {{ country.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>



        </div>


      </div>




      <div class="field col-6" *ngIf="studioData.coordinates">
        <p-gmap
          #studioMap
          (onMapReady)="setMap($event)"
          (onOverlayDragEnd)="overlayDragEnd($event)"
          [options]="mapOptions"
          [overlays]="mapOverlays"
          [style]="{'width':'100%','height':'500px'}"

        ></p-gmap>
      </div>


    </div>


    <div class="formgrid grid">
      <div class="field col-12 lg:col-3">
        <label for="phoneNumber">Telephone</label>
        <p-inputNumber
          id="phoneNumber"
          inputId="phoneNumber"
          placeholder="Phone Number"
          class="w-full"
          styleClass="number-inputs"
          [prefix]="phonePrefix"
          [useGrouping]="false"
          [(ngModel)]="studioData.landLine"
        ></p-inputNumber>
      </div>
      <div class="field col-12 lg:col-3">
        <label for="mobileNumber">Mobile phone</label>
        <p-inputNumber
          id="mobileNumber"
          inputId="mobileNumber"
          class="w-full"
          placeholder="Mobile Number"
          styleClass="number-inputs"
          [prefix]="phonePrefix"
          [useGrouping]="false"
          [(ngModel)]="studioData.phoneNumber"
        ></p-inputNumber>
      </div>

      <div class="col-12">
      </div>

      <div class="field col-12 lg:col-6 ">
        <label for="about">About</label>
        <textarea
          [(ngModel)]="studioData.description"
          [rows]="5"
          [cols]="30"
          style="width:100%"
          pInputTextarea
          id="about" type="text" class="w-full"
          [autoResize]="true"
          placeholder="Describe your Studio in English..."
        ></textarea>

      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="formgrid grid">
      <div class="col-12">
        <h5>Available Hours</h5>
      </div>

      <ng-template
        ngFor
        let-day
        [ngForOf]="availabilityDays"
        let-i="index"
      >

        <div class="col-12 field hidden-pc">


          <div
            class="mb-2 mt-2"
          >{{day.toUpperCase()}}</div>
          <p-toggleButton
            onLabel="Open"
            offLabel="Closed"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            class="mb-2 w-full"
            [(ngModel)]="studioData.availability[day].available"
          ></p-toggleButton>



          <ng-template [ngIf]="studioData.availability[day].available">
            <div style="width:50%;float:left;padding:10px;">
              <label
                class="mb-2 mt-2"
                for="{{day}}AvailabilityFrom"
              >From</label>
              <br>
              <p-calendar
                id="{{day}}AvailabilityFrom"
                class="w-full"
                showTime="true"
                [(ngModel)]="studioData.availability[day].from"
                [timeOnly]="true"
                [hourFormat]="'24'"
                [stepMinute]="60"
              ></p-calendar>
            </div>
            <div style="width:50%;float:right;padding:10px;">
              <label
                class="mb-2 mt-2"
                for="{{day}}AvailabilityTo">To</label>
              <br>
              <p-calendar
                id="{{day}}AvailabilityTo"
                class="w-full"
                showTime="true"
                [(ngModel)]="studioData.availability[day].to"
                [timeOnly]="true"
                [hourFormat]="'24'"
                [stepMinute]="60"
              ></p-calendar>
            </div>
          </ng-template>



        </div>

        <div class="col field hidden-mobile">
          <div
            class="mb-2 mt-2"
          >{{day.toUpperCase()}}</div>
          <p-toggleButton
            onLabel="Open"
            offLabel="Closed"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            class="mb-2 w-full"
            [(ngModel)]="studioData.availability[day].available"
          ></p-toggleButton>
          <ng-template [ngIf]="studioData.availability[day].available">
            <br>
            <label
              class="mb-2 mt-2"
              for="{{day}}AvailabilityFrom"
            >From</label>
            <br>
            <p-calendar
              id="{{day}}AvailabilityFrom"
              class="w-full"
              showTime="true"
              [(ngModel)]="studioData.availability[day].from"
              [timeOnly]="true"
              [hourFormat]="'24'"
              [stepMinute]="60"
            ></p-calendar>
            <br>
            <label
              class="mb-2 mt-2"
              for="{{day}}AvailabilityTo">To</label>
            <br>
            <p-calendar
              id="{{day}}AvailabilityTo"
              class="w-full"
              showTime="true"
              [(ngModel)]="studioData.availability[day].to"
              [timeOnly]="true"
              [hourFormat]="'24'"
              [stepMinute]="60"
            ></p-calendar>
          </ng-template>
        </div>

      </ng-template>


<!--      <div class="col-12 field" *ngFor="let day of availabilityDays">-->
<!--        <div-->
<!--          class="mb-2 mt-2"-->
<!--        >{{day.toUpperCase()}}</div>-->
<!--        <p-toggleButton-->
<!--          onLabel="Open"-->
<!--          offLabel="Closed"-->
<!--          onIcon="pi pi-check"-->
<!--          offIcon="pi pi-times"-->
<!--          class="mb-2 w-full"-->
<!--          [(ngModel)]="studioData.availability[day].available"-->
<!--        ></p-toggleButton>-->
<!--        <ng-template [ngIf]="studioData.availability[day].available">-->
<!--          <br>-->
<!--          <label-->
<!--            class="mb-2 mt-2"-->
<!--            for="{{day}}AvailabilityFrom"-->
<!--          >From</label>-->
<!--          <br>-->
<!--          <p-calendar-->
<!--            id="{{day}}AvailabilityFrom"-->
<!--            class="w-full"-->
<!--            showTime="true"-->
<!--            [(ngModel)]="studioData.availability[day].from"-->
<!--            [timeOnly]="true"-->
<!--            [hourFormat]="'24'"-->
<!--            [stepMinute]="30"-->
<!--          ></p-calendar>-->
<!--          <br>-->
<!--          <label-->
<!--            class="mb-2 mt-2"-->
<!--            for="{{day}}AvailabilityTo">To</label>-->
<!--          <br>-->
<!--          <p-calendar-->
<!--            id="{{day}}AvailabilityTo"-->
<!--            class="w-full"-->
<!--            showTime="true"-->
<!--            [(ngModel)]="studioData.availability[day].to"-->
<!--            [timeOnly]="true"-->
<!--            [hourFormat]="'24'"-->
<!--            [stepMinute]="30"-->
<!--          ></p-calendar>-->
<!--        </ng-template>-->
<!--      </div>-->

    </div>
  </div>

  <div class="col-12 lg:col-2 lg:col-offset-10 lg:mb-4">
    <button
      icon="pi pi-check"
      iconPos="left"
      pButton
      type="button"
      label="Save Details"
      class="p-button-success w-full"
      (click)="saveDetails()"
    ></button>
  </div>

  <div class="col-12 hidden-mobile">
    <p-divider></p-divider>
  </div>

  <!-- RENTAL INSTRUMENTS  -->
  <div class="col-12 hidden-mobile">

    <div class="grid">
      <div class="col-2">
        <h5>Instruments for Rental</h5>
      </div>
    </div>

    <div style="margin-bottom: 2em;">
      <div class="field col-4 md:col-2" *ngIf="!showNewInstrumentRow">
        <button
          pButton
          type="button"
          icon="pi pi-plus"
          class="p-button-raised p-button-rounded p-button-success"
          (click)="addInstrumentRow()"
        ></button>
        <div *ngIf="(studioEquipment | async).length === 0">
          <span>
            No instruments for rental added.
          </span>
        </div>
      </div>

      <div class="formgrid grid" *ngIf="showNewInstrumentRow">
        <div class="field col-2 md:col-2">
            <span class=" p-float-label">
                <p-dropdown
                  #instrumentType
                  id="instrumentType"
                  [autoDisplayFirst]="false"
                  [options]="instruments"
                  [(ngModel)]="newInstrument"
                  (onChange)="resetNewInstrumentBrand()"
                  optionLabel="name"
                >
                  <ng-template let-instrument pTemplate="item">
                    <div class="instrument-item">
                      <div class="till-noon-icon {{instrument.icon}}"></div>
                      <div>{{instrument.name}}</div>
                    </div>
                  </ng-template>


                </p-dropdown>
                <label for="instrumentType">Instrument Type</label>
            </span>
        </div>

      <div class="field col-2 md:col-2">
          <span class=" p-float-label">
              <p-dropdown
                #instrumentBrand
                id="instrumentBrand"
                [autoDisplayFirst]="newInstrument && newInstrument.brands.length ===1"
                [options]="newInstrument ? newInstrument.brands : []"
                [(ngModel)]="newInstrumentBrand"
                optionLabel="name"
              >
                <ng-template let-brand pTemplate="item">
                  <div class="instrument-item">
                    <img src="{{'assets/images/brands/'+brand.type+'.png'}}"
                         [class]="'icon icon-' + brand.type.toLowerCase()"/>
                    <div>{{brand.name}}</div>
                  </div>
                </ng-template>
              </p-dropdown>
              <label for="instrumentBrand">Brand</label>
          </span>
      </div>
      <div class="field col-2 md:col-2">
          <span class="p-float-label mb-3">
              <input inputTrim
                id="instrumentDescription"
                inputId="instrumentDescription"
                type="text"
                class="w-full"
                maxlength="40"
                pInputText
                [(ngModel)]="newInstrumentName"
              />
              <label for="instrumentDescription">Name</label>
          </span>
      </div>
      <div class="field col-4 md:col-2">
          <span class="p-float-label mb-3">
              <p-inputNumber
                [(ngModel)]="newInstrumentPrice"
                id="instrumentPrice"
                mode="currency"
                [currency]="businessCurrency"
                suffix=" /hr"
              ></p-inputNumber>
              <label for="instrumentPrice">Price</label>
          </span>
      </div>
      <div class="field col-2 md:col-2">
          <span class=" mr-3">
            <label for="instrumentPrice">Available :</label>
          </span>
        <p-inputSwitch [(ngModel)]="newInstrumentAvailability"></p-inputSwitch>

        </div>
        <div class="field col-2 md:col-1">
          <p-button
            label="Cancel"
            styleClass="p-button-secondary p-button-outlined secondary-button"
            (click)="clearNewInstrument()"
          ></p-button>
        </div>
        <div class="field col-2 md:col-1">
          <p-button
            label="Save"
            styleClass="p-button-primary"
            (click)="addNewInstrument()"
          ></p-button>
      </div>
      </div>
    </div>

    <div *ngIf="studioEquipment | async as studioInstruments">
      <div class="formgrid grid" *ngFor="let instrument of studioInstruments">
        <div class="field col-2 md:col-2">
            <span class=" p-float-label">
                <p-dropdown
                  #instrumentType
                  class=" w-full"
                  [autoDisplayFirst]="false"
                  [options]="instruments"
                  [(ngModel)]="instrument.equipment.category"
                  optionLabel="name"
                  optionValue="type"
                  [disabled]="true"
                ></p-dropdown>
                <label for="instrumentType">Instrument Type</label>
            </span>
        </div>
        <div class="field col-2 md:col-2">
            <span class="p-float-label mb-3">
              <p-dropdown
                #instrumentBrand
                class=" w-full"
                [autoDisplayFirst]="false"
                [options]="instrumentBrandMap.get(instrument.equipment.category)"
                [(ngModel)]="instrument.equipment.brand"
                optionLabel="name"
                optionValue="type"
                [disabled]="true"
              ></p-dropdown>
              <label for="instrumentBrand">Brand</label>
            </span>
        </div>
        <div class="field col-2 md:col-2">
            <span class="p-float-label mb-3">
              <input inputTrim
                inputId="instrumentDescription"
                type="text"
                class="w-full"
                maxlength="40"
                pInputText
                [(ngModel)]="!instrument.edit ? instrument.equipment.name : instrument.equipment.editName"
                [disabled]="!instrument.edit"
              />
              <label for="instrumentDescription">Name</label>
            </span>
        </div>
        <div class="field col-4 md:col-2">
            <span class="p-float-label mb-3">
              <p-inputNumber
                [(ngModel)]="!instrument.edit ? instrument.price : instrument.editPrice"
                inputId="instrumentPrice"
                mode="currency"
                [currency]="businessCurrency"
                suffix=" /hr"
                [disabled]="!instrument.edit"
              ></p-inputNumber>
              <label for="instrumentPrice">Price</label>
            </span>
        </div>
        <div class="field col-2 md:col-2">
            <span class=" mr-3">
              <label for="instrumentPrice">Available :</label>
            </span>
          <p-inputSwitch
            [(ngModel)]="!instrument.edit ? instrument.available : instrument.editAvailable"
            [disabled]="!instrument.edit"
          ></p-inputSwitch>
        </div>

        <ng-template [ngIf]="instrument.edit">
          <div class="field col-2 md:col-1">
            <p-button
              label="Cancel"
              styleClass="p-button-secondary p-button-outlined secondary-button"
              (click)="toggleInstrumentEdit(instrument)"
            ></p-button>
          </div>
          <div class="field col-2 md:col-1">
            <p-button
              label="Save"
              styleClass="p-button-primary"
              (click)="editInstrument(instrument.id , instrument)"
            ></p-button>
          </div>
        </ng-template>

        <div class="field col-4 md:col-2">
          <ng-template [ngIf]="!instrument.edit">
            <button
              pButton
              type="button"
              icon="pi pi-pencil"
              label="Edit"
              class="mr-2 mb-2 p-button p-button-info p-button-text"
              (click)="toggleInstrumentEdit(instrument)"
            ></button>

            <button
              pButton
              type="button"
              icon="pi pi-times"
              class="mr-2 mb-2 p-button p-button-danger p-button-text"
              label="Delete"
              (click)="removeInstrument( instrument.id)"
            ></button>
          </ng-template>

        </div>
      </div>
    </div>

  </div>

  <div class="col-12 hidden-mobile">
    <p-divider></p-divider>
  </div>

  <!--ROOMS-->
  <div class="col-10 hidden-mobile">
    <h4>Available Rooms</h4>
  </div>
  <div class="col-2 hidden-mobile">
    <button
      icon="pi pi-plus"
      iconPos="left"
      pButton
      pRipple
      type="button"
      label="Add Room"
      class="p-button-secondary secondary-button"
      (click)="newRoomDialog = true"
    ></button>
  </div>

  <div class="col-12 hidden-mobile" style="padding-left: 0 !important; padding-right: 0 !important"
       *ngFor="let room of studioData.rooms; let i = index;">
    <p-accordion>
      <p-accordionTab class="roomHeader" [(selected)]="activeState[i]">
        <ng-template pTemplate="header">
          <span class="label">Room : {{room.name}}</span>
            <button
              pButton
              type="button"
              class="p-button p-button-info p-button-text"
              icon="pi pi-pencil"
              (click)="editRoomShow($event,room)"
            ></button>
        </ng-template>

        <!--ROOM IMAGES-->
            <div class="col-11 col-offset-1">
              <h5>Images</h5>
            </div>
            <div class="col-11 col-offset-1">
              <div class="formgrid grid">
                <div class="field col-8 md:col-8">
                  <p-carousel
                    *ngIf="room.media && room.media.length > 0"
                    [value]="room.media"
                    [numVisible]="3"
                    [numScroll]="3"
                    [circular]="false"
                    [responsiveOptions]="responsiveOptions"
                  >
                    <ng-template pTemplate="header">
                    </ng-template>
                    <ng-template let-roomImage pTemplate="item">
                      <div class="product-item">
                        <div class="product-item-content">
                          <div class="mb-3">
                            <img
                              src="{{roomImage['link']}}"
                              [alt]="roomImage.name"
                              class="product-image roomImage"
                            />
                          </div>
                          <div>
                            <div class="car-buttons mt-5">
                              <p-button
                                type="button"
                                styleClass="p-button-danger p-button-rounded mr-2"
                                icon="pi pi-times"
                                (onClick)="removeImage(roomImage , 'room' , room.id)"
                              ></p-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </p-carousel>

              <div *ngIf="!room.media || room.media.length === 0">
                <div class="field" style=" display: flex; align-items: center;">
                  <p-fileUpload
                    #roomUploader
                    mode="basic"
                    accept="image/*"
                    [customUpload]="true"
                    [auto]="true"
                    (uploadHandler)="imageUpload($event, 'media' , 'room', room.id, roomUploader)">
                  </p-fileUpload>
                </div>
                <span>
                  No images uploaded for this room.
                </span>
              </div>
            </div>

            <div *ngIf="room.media && room.media.length > 0"
              class="field col-2 md:col-2" style=" display: flex; align-items: center;">
              <p-fileUpload
                #roomUploader
                mode="basic"
                accept="image/*"
                chooseLabel="Add Image"
                [customUpload]="true"
                [auto]="true"
                (uploadHandler)="imageUpload($event, 'media' , 'room', room.id, roomUploader)">
              </p-fileUpload>
            </div>
          </div>
        </div>

        <!--ROOM SERVICES-->
        <div class="col-11 col-offset-1">
          <h5>Available Services</h5>
        </div>
        <div class="col-11  col-offset-1">

          <div *ngIf="!showNewServiceRow" style="margin-bottom: 2em;">
            <button
              pButton
              type="button"
              icon="pi pi-plus"
              class="p-button-raised p-button-rounded p-button-success"
              (click)="addServiceRow(room)"
            ></button>
            <div *ngIf="room.services.length === 0">
              <span>
                No services added for this room.
              </span>
            </div>
          </div>

          <div class="formgrid grid" *ngIf="showNewServiceRow">
            <div class="field col-2 md:col-2">
                <span class=" p-float-label">
                    <p-dropdown
                      class=" w-full"
                      #newServiceType
                      id="newServiceType"
                      [autoDisplayFirst]="false"
                      [options]="availableServices"
                      [(ngModel)]="room.newServiceType"
                      optionLabel="name"
                      optionValue="code"
                    ></p-dropdown>
                    <label for="newServiceType">Service Type</label>
                </span>
            </div>
            <div class="field col-2 md:col-2">
                <span class="p-float-label mb-3">
                  <p-inputNumber
                    [(ngModel)]="room.newServicePrice"
                    #newServicePrice
                    id="newServicePrice"
                    mode="currency"
                    class=" w-full"
                    suffix=" /hr"
                    [currency]="businessCurrency"
                  ></p-inputNumber>
                  <label for="newServicePrice">Service Price</label>
                </span>
            </div>

            <div class="field col-2 md:col-1 col-offset-4 md:col-offset-4">
              <p-button
                label="Cancel"
                styleClass="p-button-secondary p-button-outlined secondary-button"
                (click)="clearNewService(room)"
              ></p-button>
            </div>
            <div class="field col-2 md:col-1">
              <p-button
                label="Save"
                styleClass="p-button-primary"
                (click)="addNewService(room)"
              ></p-button>
            </div>
          </div>

          <ng-template [ngIf]="room.services && room.services.length > 0">
            <div class="formgrid grid" *ngFor="let service of room.services">
              <div class="field col-2 md:col-2">
                  <span class=" p-float-label">
                    <p-dropdown
                      class=" w-full"
                      #serviceType
                      [options]="services"
                      [(ngModel)]="service.type"
                      optionLabel="name"
                      optionValue="code"
                      [disabled]="true"
                    ></p-dropdown>
                    <label for="instrumentType">Service Type</label>
                  </span>
              </div>
              <div class="field col-4 md:col-2">
                  <span class=" p-float-label">
                    <p-inputNumber
                      [(ngModel)]="!service.edit ? service.price : service.editPrice"
                      mode="currency"
                      [currency]="businessCurrency"
                      class="w-full"
                      suffix=" /hr"
                      id="servicePrice"
                      [disabled]="!service.edit"
                    ></p-inputNumber>
                    <label for="servicePrice">Service Price</label>
                  </span>
              </div>

              <ng-template [ngIf]="service.edit">
                <div class="field col-2 md:col-1 col-offset-4 md:col-offset-4">
                  <p-button
                    label="Cancel"
                    styleClass="p-button-secondary p-button-outlined secondary-button"
                    (click)="toggleServiceEdit(service)"
                  ></p-button>
                </div>
                <div class="field col-2 md:col-1">
                  <p-button
                    label="Save"
                    styleClass="p-button-primary"
                    (click)="editService(room.id, service)"
                  ></p-button>
                </div>
              </ng-template>

              <ng-template [ngIf]="!service.edit">
                <div class="field col-2 md:col-2 col-offset-2 md:col-offset-1">
                  <button
                    pButton
                    type="button"
                    icon="pi pi-plus"
                    label="Add Discount"
                    class="mr-2 p-button p-button-info p-button-text"
                    (click)="showNewDiscount(service, 'discount')"
                  ></button>
                </div>
                <div class="field col-2 md:col-2">
                  <button
                    pButton
                    type="button"
                    icon="pi pi-plus"
                    label="Add Package"
                    class="mr-2 p-button p-button-info p-button-text"
                    (click)="showNewDiscount(service, 'package')"
                  ></button>
                </div>
                <div class="field col-2 md:col-1 ">
                  <button
                    pButton
                    type="button"
                    icon="pi pi-pencil"
                    label="Edit"
                    class="mr-2 p-button p-button-info p-button-text"
                    (click)="toggleServiceEdit(service)"
                  ></button>
                </div>
                <div class="field col-2 md:col-1">
                  <button
                    pButton
                    type="button"
                    icon="pi pi-times"
                    class="ml-2 p-button p-button-danger p-button-text"
                        label="Delete"
                    (click)="removeService( room , service.id)"
                  ></button>
                </div>

                <div
                  *ngIf="service.discounts && service.discounts.length > 0"
                  class="col-12"
                  style="margin-bottom: 2em;"
                >

                    <p-chip
                      [style]="{margin: '0.5em'}"
                      *ngFor="let discount of service.discounts"
                      [removable]="true"
                      (click)="removeDiscount($event, room , service , discount)"
                    >

                      <ng-template
                        [ngIf]="!discount.rules.cutoff || discount.rules.cutoff === 0"
                      >
                        <span class="p-2">{{discount.name ? discount.name + ' : ' : 'Discount' + ' : '}}</span>
                        <span> {{discount.rules.percentage}}% off </span>
                      </ng-template>

                      <ng-template
                        [ngIf]="discount.rules.cutoff > 0"
                      >
                        <span class="p-2">{{discount.name ? discount.name + ' : ' : 'Package' + ' : '}}</span>
                        <span> - {{discount.rules.payoff}} {{businessCurrencySymbol}} off </span>
                        <span class="p-2" *ngIf="discount.rules.cutoff"> When you book {{discount.rules.cutoff}} hrs</span>
                      </ng-template>


<!--                      <span>You get {{discount.rules.payoff}} hr more</span>-->
                    </p-chip>

                </div>

              </ng-template>


              <p-dialog
                [(visible)]="service.addNewDiscountDialog"
                [style]="{width: '450px'}"
                header="Add Discount"
                [modal]="true"
                styleClass="p-fluid">

                <ng-template pTemplate="content">
                  <div class="field">
                    <label for="roomName">Discount Label</label>
                    <input
                      #discountName
                      id="discountName"
                      type="text"
                      class="w-full"
                      pInputText
                      [(ngModel)]="newDiscountName"
                    />
                  </div>

<!--                  <div class="field">-->
<!--                    <label for="roomName">After Amount of Hours</label>-->
<!--                    <p-inputNumber-->
<!--                      id="discountCutOff"-->
<!--                      [(ngModel)]="newDiscountCutOff"-->
<!--                      required autofocus-->
<!--                      [min]="1" [max]="6"-->
<!--                    >-->
<!--                    </p-inputNumber>-->
<!--                    <small class="p-invalid" *ngIf="!newDiscountCutOff">Amount of Hours is required.</small>-->
<!--                  </div>-->

                  <div class="field">
                    <label for="roomName">Percentage</label>
                    <p-inputNumber
                      id="discountPercentage"
                      [(ngModel)]="newDiscountPercentage"
                      required autofocus
                      [min]="1" [max]="100"
                    >
                    </p-inputNumber>
                    <small class="p-invalid" *ngIf="!newDiscountPercentage">Percentage is required.</small>
                  </div>

                </ng-template>

                <ng-template pTemplate="footer">
                  <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-secondary p-button-outlined secondary-button" (click)="service.addNewDiscountDialog = false"></button>
                  <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-primary" (click)="addNewDiscount(room, service, 'discount')"></button>
                </ng-template>
              </p-dialog>


              <p-dialog
                [(visible)]="service.addNewPackageDialog"
                [style]="{width: '450px'}"
                header="Add Package"
                [modal]="true"
                styleClass="p-fluid">

                <ng-template pTemplate="content">
                  <div class="field">
                    <label for="roomName">Discount Label</label>
                    <input
                      #discountName
                      id="packageName"
                      type="text"
                      class="w-full"
                      pInputText
                      [(ngModel)]="newPackageName"
                    />
                  </div>

                  <div class="field">
                    <label for="roomName">On Amount of Hours</label>
                    <p-inputNumber
                      id="packageCutOff"
                      [(ngModel)]="newPackageCutOff"
                      required autofocus
                      [min]="1" [max]="6"
                    >
                    </p-inputNumber>
                    <small class="p-invalid" *ngIf="!newPackageCutOff">Amount of Hours is required.</small>
                  </div>

                  <div class="field">
                    <label for="roomName">Normal Amount of Price : {{newPackageCutOff * service.price}}</label>
                  </div>

                  <div class="field">
                    <label for="roomName">Pay Off</label>
                    <p-inputNumber
                      id="packagePayoff"
                      [(ngModel)]="newPackagePayOff"
                      required autofocus
                      [min]="1" [max]="service.price * newPackageCutOff"
                    >
                    </p-inputNumber>
                    <small class="p-invalid" *ngIf="!newPackagePayOff">Pay Off is required.</small>
                  </div>

                  <div class="field">
                    <label for="roomName">Discounted Price : {{newPackageCutOff * service.price - newPackagePayOff}}</label>
                  </div>

                </ng-template>

                <ng-template pTemplate="footer">
                  <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-secondary p-button-outlined secondary-button" (click)="service.addNewPackageDialog = false"></button>
                  <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-primary" (click)="addNewDiscount(room, service, 'package')"></button>
                </ng-template>
              </p-dialog>



            </div>
          </ng-template>
        </div>

        <!--ROOM EQUIPMENT-->
        <div class="col-11  col-offset-1">
          <h5>Room Equipment</h5>
        </div>
        <div class="col-11  col-offset-1">
          <div *ngIf="!showNewEquipmentRow" style="margin-bottom: 2em;">
            <button
              pButton
              type="button"
              icon="pi pi-plus"
              class="p-button-raised p-button-rounded p-button-success"
              (click)="addEquipmentRow()"
            ></button>
            <div *ngIf="!room.equipment || room.equipment.length === 0">
              <span>
                No equipment added for this room.
              </span>
            </div>
          </div>

          <div class="formgrid grid" *ngIf="showNewEquipmentRow">
            <div class="field col-2 md:col-2">
                <span class=" p-float-label">
                  <p-dropdown
                    class=" w-full"
                    #equipmentCategory
                    id="equipmentCategory"
                    [autoDisplayFirst]="false"
                    [options]="equipments"
                    [(ngModel)]="room.newEquipment"
                    (onChange)="resetNewEquipmentBrand(room)"
                    optionLabel="name">
                    <ng-template let-equipment pTemplate="item">
                      <div class="instrument-item">
                      <div class="till-noon-icon {{equipment.icon}}"></div>
                      <div>{{equipment.name}}</div>
                     </div>
                    </ng-template>
                  </p-dropdown>
                  <label for="equipmentCategory">Equipment Category</label>
                </span>
            </div>

            <div class="field col-2 md:col-2">
                <span class="p-float-label mb-3">
              <p-dropdown
                #equipmentBrand
                id="equipmentBrand"
                [autoDisplayFirst]="room.newEquipment && room.newEquipment.brands.length ===1"
                [options]="room.newEquipment ? room.newEquipment.brands : []"
                [(ngModel)]="room.newEquipmentBrand"
                optionLabel="name">
                <ng-template let-equipment pTemplate="item">
                  <div class="instrument-item">
                    <img src="{{'assets/images/brands/'+equipment.type+'.png'}}"
                         [class]="'icon icon-' + equipment.type.toLowerCase()"/>
                    <div>{{equipment.name}}</div>
                  </div>
                </ng-template>

              </p-dropdown>
              <label for="equipmentBrand">Brand</label>
          </span>
            </div>

            <div class="field col-2 md:col-2">
                <span class="p-float-label mb-3">
                  <input inputTrim
                    #equipmentName
                    id="equipmentName"
                    type="text"
                    class="w-full"
                    pInputText
                    maxlength="40"
                    [(ngModel)]="room.newEquipmentName"
                  />
                  <label for="instrumentDescription">Name</label>
                </span>
            </div>

            <div class="field col-2 md:col-2">
                <span class="p-float-label mb-3">
                  <input inputTrim
                    #equipmentDescription
                    id="equipmentDescription"
                    type="text"
                    class="w-full"
                    pInputText
                    maxlength="125"
                    [(ngModel)]="room.newEquipmentDescription"
                  />
                  <label for="instrumentDescription">Description</label>
                </span>
            </div>

            <div class="field col-2 md:col-1">
              <p-button
                label="Cancel"
                styleClass="p-button-secondary p-button-outlined secondary-button"
                (click)="clearNewEquipment()"
              ></p-button>
            </div>
            <div class="field col-2 md:col-1">
              <p-button
                  label="Save"
                  styleClass="p-button-primary"
                  (click)="addNewEquipment(room)"
                ></p-button>
            </div>
          </div>

          <ng-template [ngIf]="room.equipment && room.equipment.length > 0">
            <div class="formgrid grid" *ngFor="let equipment of room.equipment">
              <div class="field col-4 md:col-2">
                  <span class=" p-float-label">
                    <p-dropdown
                      class=" w-full"
                      #equipmentCategory
                      [options]="equipments"
                      [(ngModel)]="equipment.category"
                      optionLabel="name"
                      optionValue="type"
                      [disabled]="true"
                    ></p-dropdown>
                    <label for="equipmentCategory">Equipment Category</label>
                  </span>
              </div>
              <div class="field col-2 md:col-2">
                  <span class="p-float-label mb-3">
                    <p-dropdown
                      #equipmentBrand
                      class=" w-full"
                      [autoDisplayFirst]="false"
                      [options]="equipmentBrandMap.get(equipment.category)"
                      [(ngModel)]="equipment.brand"
                      optionLabel="name"
                      optionValue="type"
                      [disabled]="true"
                    ></p-dropdown>
                    <label for="equipmentBrand">Brand</label>
                  </span>
              </div>
              <div class="field col-2 md:col-2">
                  <span class="p-float-label mb-3">
                    <input inputTrim
                      #equipmentName
                      type="text"
                      class="w-full"
                      pInputText
                      [(ngModel)]="!equipment.edit ? equipment.name : equipment.editName"
                      [disabled]="!equipment.edit"
                      maxlength="40"
                    />
                    <label for="instrumentDescription">Name</label>
                  </span>
              </div>
              <div class="field col-2 md:col-2">
                  <span class="p-float-label mb-3">
                    <input inputTrim
                      #equipmentDescription
                      type="text"
                      class="w-full"
                      pInputText
                      [(ngModel)]="!equipment.edit ? equipment.description : equipment.editDescription"
                      [disabled]="!equipment.edit"
                      maxlength="125"
                    />
                    <label for="instrumentDescription">Description</label>
                  </span>
              </div>

              <ng-template [ngIf]="equipment.edit">
                <div class="field col-2 md:col-1">
                  <p-button
                    label="Cancel"
                    styleClass="p-button-secondary p-button-outlined secondary-button"
                    (click)="toggleEquipmentEdit(equipment)"
                  ></p-button>
                </div>
                <div class="field col-2 md:col-1">
                  <p-button
                    label="Save"
                    styleClass="p-button-primary"
                    (click)="editEquipment(room.id, equipment)"
                  ></p-button>
                </div>
              </ng-template>

              <ng-template [ngIf]="!equipment.edit">
                <div class="field col-2 md:col-1">
                  <button
                    pButton
                    type="button"
                    icon="pi pi-pencil"
                    label="Edit"
                    class="mr-2 p-button p-button-info p-button-text"
                    (click)="toggleEquipmentEdit(equipment)"
                  ></button>
                </div>
                <div class="field col-2 md:col-1">
                  <button
                    pButton
                    type="button"
                    icon="pi pi-times"
                    class="ml-2 p-button p-button-danger p-button-text"
                    label="Delete"
                    (click)="removeEquipment( room.id , equipment.id)"
                  ></button>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </div>

        <div class="col-2 col-offset-10">
          <!-- DELETE ROOM -->
          <button
            pButton
            type="button"
            label="Delete Room"
            class="p-button p-button-danger p-button-text"
            icon="pi pi-times"
            (click)="removeRoom(room.id)"
          ></button>
        </div>

      </p-accordionTab>
    </p-accordion>
  </div>




  <p-dialog
    [(visible)]="studioImagesDialog"
    [style]="{width: '450px'}"
    header="Studio Images"
    [modal]="true"
    styleClass="p-fluid">
    <p-carousel
      *ngIf="studioData.studioImages && studioData.studioImages.length > 0"
      [value]="studioData.studioImages"
      [numVisible]="1"
      [numScroll]="1"
      [circular]="true"
      orientation="vertical"
      [responsiveOptions]="responsiveOptions"
    >
      <ng-template pTemplate="header">
      </ng-template>
      <ng-template let-roomImage pTemplate="item">


        <div class="grid">
          <div class="col-8">
            <img
              src="{{roomImage['link']}}"
              [alt]="roomImage.name"
              class="product-image"
              style="width:100%;height: auto;"
            />
          </div>
          <div class="col-4" style="display: flex;align-items: center;justify-content: center;">
            <p-button
              type="button"
              styleClass="p-button-danger p-button-rounded mr-2"
              icon="pi pi-times"
              (onClick)="removeImage(roomImage , 'studio' , studioData.id)"
            ></p-button>
          </div>
        </div>
      </ng-template>
    </p-carousel>
  </p-dialog>

</div>


<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>


<p-dialog
  [(visible)]="newRoomDialog"
  [style]="{width: '450px'}"
  header="New Room"
  [modal]="true"
  styleClass="p-fluid">
  <ng-template pTemplate="content">
    <div class="field">
      <label for="roomName">Name</label>
      <input type="text" pInputText id="roomName" [(ngModel)]="newRoomName" required autofocus/>
      <small class="p-invalid" *ngIf="!newRoomName">Name is required.</small>
    </div>

  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-secondary p-button-outlined secondary-button" (click)="hideDialog()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-primary" (click)="addNewRoom()"></button>
  </ng-template>
</p-dialog>


<p-dialog
  [(visible)]="editRoomNameDialog"
  [style]="{width: '450px'}"
  header="Rename room"
  [modal]="true"
  styleClass="p-fluid">

  <ng-template pTemplate="content">
    <div class="formgrid grid">
      <div class="field col-12 md:col-12">
        <span class=" p-float-label">

          <input type="text" pInputText id="name" [(ngModel)]="roomToEdit.editedName" required autofocus/>
          <label for="name">Name</label>
          <small class="p-invalid" *ngIf="!roomToEdit.editedName">Name is required.</small>
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">

    <button
      pButton
      pRipple
      type="button"
      class="p-button-secondary p-button-outlined secondary-button"
      label="Cancel"
      (click)="hideDialog()"
    ></button>

    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-primary" (click)="editRoom()"></button>
  </ng-template>
</p-dialog>


<p-dialog
  header="Upload Image"
  [(visible)]="uploadImageDialog"
  [style]="{width: '50vw'}"
  [modal]="true"
  styleClass="p-fluid"
>

  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [containWithinAspectRatio]="true"
    [aspectRatio]="16 / 9"
    format="jpeg"
    [imageQuality]="70"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded($event)"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>


  <ng-template pTemplate="footer">
    <button
      icon="pi pi-check"
      iconPos="left"
      pButton
      type="button"
      label="Upload Cover Image"
      class="p-button-success w-full"
      (click)="imageUpload($event, 'cover' , 'studio')"
    ></button>
  </ng-template>
</p-dialog>




