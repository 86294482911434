<!--<div class="grid bandbeatPage" *ngIf="bookingsT | async as bookingsData">-->
<div class="grid bandbeatPage">
  <div class="col-12 lg:col-4" *ngIf="bookingsSummaries | async">
    <div class="card widget-list">
      <ng-template ngFor let-child let-i="index" [ngForOf]="bookingTypes">
        <div
          (click)="onBookingTypeSelection(child.code)"
          style="padding: 12px; cursor: pointer"
          [ngClass]="{ 'widget-option': selectedBookingType === child.code }"
          class="mb-4 px-3 flex align-items-center justify-content-start"
        >
          <div class="option-title">
            <i class="material-icons-outlined">{{ child.icon }}</i>
            <span> {{ child.name }} </span>
          </div>
          <div class="option-num">
            <span>{{ child.amount }}</span>
          </div>

          <div class="option-info">
            <i pTooltip="{{ child.info }}" class="material-icons-outlined"
              >info</i
            >
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="col-12 lg:col-8">
    <div class="grid">
      <div class="col-12">
        <span style="font-weight: 400; font-size: 12px; line-height: 16px">{{
          today | dateParser : "EEE dd,MMM yyyy"
        }}</span>
        <h3 style="margin-top: 4px; margin-bottom: 8px">
          Good {{ timeOfDay | timeDay }}!
        </h3>
        <p>Here's what’s happening in your Bandbeat account today</p>
      </div>

      <div class="col-12">
        <p-dataView
          #dv
          [value]="bookings | async"
          [paginator]="true"
          [rows]="dataViewRows"
          [totalRecords]="totalResults"
          [first]="dataViewFirst"
          [lazy]="true"
          (onPage)="onPageChange($event)"
          filterBy="invite.user.name"
          [loading]="loading"
          layout="list"
        >
          <ng-template let-booking pTemplate="listItem">
            <div class="col-12">
              <div class="product-list-item grid grid-nogutter">
                <div class="col-12">
                  <div class="grid">
                    <div class="col-fixed" style="width: 74px">
                      <div class="bookingLogo">
                        <img
                          *ngIf="booking.bookingType === 'band'"
                          [src]="
                            booking.booker.avatar
                              ? booking.booker.avatar['link']
                              : 'assets/images/bandbeat/sample_user_logo.png'
                          "
                          style="border-radius: 50%; vertical-align: middle"
                          [alt]="booking.label"
                        />
                        <img
                          *ngIf="booking.bookingType === 'solo'"
                          [src]="
                            booking.user.avatar
                              ? booking.user.avatar['link']
                              : 'assets/images/bandbeat/sample_user_logo.png'
                          "
                          style="border-radius: 50%; vertical-align: middle"
                          [alt]="booking.label"
                        />
                        <img
                          *ngIf="booking.bookingType === 'offline'"
                          [src]="
                            studioLogo
                              ? studioLogo['link']
                              : 'assets/images/bandbeat/sample_studio_logo.png'
                          "
                          style="border-radius: 50%; vertical-align: middle"
                          [alt]="booking.label"
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="grid">
                        <div class="col-8">
                          <div class="product-name">
                            <ng-template
                              [ngIf]="booking.bookingType === 'offline'"
                            >
                              <span style="font-weight: 700">You </span>
                              reserved an
                              <span class="keyword">unavailable slot</span>
                            </ng-template>
                            <ng-template
                              [ngIf]="booking.bookingType !== 'offline'"
                            >
                              <span style="font-weight: 700"
                                >{{ booking.booker.name }}
                              </span>
                              {{ booking.status | verbalizeBookingStatus }} a
                              <span class="keyword">{{
                                booking.service.type
                              }}</span>
                            </ng-template>
                            <br />
                            <small
                              >{{
                                booking.bookingType === "offline"
                                  ? "Reserved"
                                  : "Requested"
                              }}
                              on {{ booking.createdAt | dateParser }}</small
                            >
                          </div>
                        </div>
                        <div
                          class="col-4"
                          style="display: flex; flex-direction: row-reverse"
                        >
                          <button
                            *ngIf="booking.bookingType !== 'offline'"
                            pButton
                            type="button"
                            [label]="
                              isMobile
                                ? ''
                                : booking.details
                                ? 'View Less'
                                : 'View More'
                            "
                            [icon]="
                              booking.details
                                ? 'pi pi-chevron-up'
                                : 'pi pi-chevron-down'
                            "
                            class="p-button-text view-more-button"
                            iconPos="right"
                            (click)="booking.details = !booking.details"
                          ></button>

                          <button
                            *ngIf="booking.bookingType === 'offline'"
                            pButton
                            type="button"
                            [label]="isMobile ? '' : 'Remove'"
                            [icon]="'pi pi-times'"
                            class="p-button-text p-button-danger"
                            iconPos="right"
                            (click)="deleteBooking(booking)"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-fixed booking-notes-padding"
                  style="width: 74px"
                ></div>
                <div class="col booking-notes">
                  <span [style]="booking.reported?'color: red;':''">
                    <i class="material-icons-outlined color-white">calendar_month</i>
                    {{ booking.bookingDate | dateParser : "dd-MM-yyyy" }}</span
                  >
                  <span [style]="booking.reported?'color: red;':''">
                    <i class="material-icons-outlined color-white">schedule</i>
                    {{ booking.bookingDate | dateParser : "HH:mm" }}</span
                  >
                  <span [style]="booking.reported?'color: red;':''">
                    <i class="material-icons-outlined color-white">timelapse</i>
                    {{ booking.duration }} hour(s)</span
                  >
                  <span *ngIf="booking.bookingType === 'solo'" [style]="booking.reported?'color: red;':''">
                    <i class="pi pi-user color-white"></i> Solo
                  </span>
                  <span *ngIf="booking.bookingType === 'band'" [style]="booking.reported?'color: red;':''">
                    <i class="pi pi-users color-white"></i> {{ booking.team.name }}
                  </span>

                  <span
                    *ngIf="
                      (booking.status === 'planning' ||
                        booking.status === 'pending-confirmation' ||
                        booking.status === 'studio-declined') &&
                      booking.recurringDates &&
                      booking.recurringDates.length > 1
                    "
                  >
                    <i class="pi pi-history"></i>
                    {{ booking.recurringDates.length - 1 }}
                    {{
                      booking.recurringDates.length - 1 === 1
                        ? "more date"
                        : "more dates"
                    }}</span
                  >
                </div>

                <div
                  class="col-12 bookingDetails"
                  *ngIf="booking.details"
                  style="padding-top: 20px"
                >
                  <div class="grid">
                    <div
                      class="col-6 md:col-4 lg:col-2"
                      *ngIf="booking.bookingType === 'band'"
                    >
                      <h5>Band</h5>
                      <span>{{ booking.team.name }}</span>
                    </div>
                    <div
                      class="col-6 md:col-4 lg:col-2"
                      *ngIf="booking.bookingType === 'solo'"
                    >
                      <h5>Solo</h5>
                      <span>{{ booking.user.name }}</span>
                    </div>
                    <div class="col-6 md:col-4 lg:col-2">
                      <h5>Booking id</h5>
                      <span *ngIf="booking.shortId"
                        >#{{ booking.shortId }}</span
                      >
                      <span *ngIf="!booking.shortId">#xxxxxxx</span>
                    </div>
                    <div class="col-6 md:col-4 lg:col-2">
                      <h5>Service</h5>
                      <span *ngIf="booking.service">{{
                        booking.service.type
                      }}</span>
                    </div>
                    <div class="col-6 md:col-4 lg:col-2">
                      <h5>Room</h5>
                      <span>{{ booking.room.name }}</span>
                    </div>
                    <div class="col-6 md:col-4 lg:col-2">
                      <h5>Members</h5>
                      <ng-template [ngIf]="booking.bookingType === 'band'">
                        <span>{{ booking.members.length }}</span>
                      </ng-template>
                    </div>
                    <div class="col-6 md:col-4 lg:col-2">
                      <h5>Instruments</h5>
                      <span>{{
                        booking.primaryMusicRoles.join(", ") | titlecase
                      }}</span>
                    </div>

                    <div class="col-6 md:col-4 lg:col-2">
                      <h5>Rentals</h5>
                      <ng-template [ngIf]="booking.instruments.length > 0">
                        <span>{{ booking.instrumentsLabels }}</span>
                      </ng-template>
                    </div>
                    <div class="col-6 md:col-4 lg:col-2">
                      <h5>Price</h5>
                      <span
                        >{{ booking.totalPrice }}
                        {{ getCurrency(booking) }}</span
                      >
                    </div>
                    <div class="col-4">
                      <h5>Notes</h5>
                      <span
                        style="overflow-wrap: break-word; line-break: anywhere"
                        >{{ booking.notes[0]?.message.substring(0, 30) }}</span
                      >
                    </div>
                    <div class="col-6 md:col-4 lg:col-2">
                      <h5>Contact Phone</h5>
                      <span>{{
                        booking.booker.phoneNumber &&
                        booking.booker.phoneNumber !== 6900000000
                          ? booking.booker.phoneNumber
                          : " N/A"
                      }}</span>
                    </div>
                  </div>

                  <div class="col-12">
                    <div
                      class="grid grid-nogutter next-buttons"
                      style="min-height: 48px"
                    >
                      <p-button
                        *ngIf="
                          booking.status !== 'studio-declined' &&
                          booking.status !== 'studio-canceled' &&
                          booking.status !== 'user-declined' &&
                          booking.status !== 'user-canceled'
                        "
                        label="Show on calendar"
                        styleClass="p-button-text "
                        class="left-button"
                        (onClick)="showOnCalendar(booking)"
                      ></p-button>
                      <p-button
                        label="View details"
                        styleClass="p-button-text "
                        [ngClass]="{
                          'left-next-button':
                            booking.status !== 'user-declined',
                          'left-button': booking.status === 'user-declined'
                        }"
                        (onClick)="viewDetails(booking)"
                      ></p-button>
                      <p-button
                        *ngIf="
                          booking.status !== 'studio-declined' &&
                          booking.status !== 'studio-canceled' &&
                          booking.status !== 'user-declined' &&
                          booking.status !== 'user-canceled' &&
                          booking.status !== 'user-not-found' &&
                          booking.status !== 'user-delete-decline' &&
                          booking.reported !== true &&
                          !(
                            booking.status === 'studio-confirmed' &&
                            booking.bookingCode &&
                            booking.bookingCode === 'completed-bookings'
                          )
                        "
                        [label]="
                          booking.status === 'studio-confirmed'
                            ? 'Cancel'
                            : 'Decline'
                        "
                        styleClass="p-button-danger p-button-outlined "
                        (onClick)="declineBooking(booking)"
                      ></p-button>
                      <p-button
                        *ngIf="
                          booking.status !== 'studio-declined' &&
                          booking.status !== 'studio-confirmed' &&
                          booking.status !== 'studio-canceled' &&
                          booking.status !== 'user-declined' &&
                          booking.status !== 'user-canceled' &&
                          booking.status !== 'user-delete-decline' &&
                          booking.reported !== true &&
                          booking.status !== 'user-not-found'
                        "
                        label="Accept"
                        class="p-button-success"
                        (onClick)="confirmBooking(booking, true)"
                      ></p-button>
                      <p-button
                        *ngIf="
                          booking.status === 'studio-confirmed' &&
                          booking.bookingCode &&
                          booking.bookingCode === 'completed-bookings' &&
                          booking.reported !== true
                        "
                        label="Report"
                        styleClass="p-button-danger"
                        (onClick)="reportBooking(booking)"
                      ></p-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </p-dataView>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
