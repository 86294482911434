import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable } from "rxjs";
import {HelperService} from '../common/helper.service';
import { ProfileService } from "../common/profile.service";
import { StudiosService } from "../studios/studios.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private notifications: BehaviorSubject<any>;
  public readonly notifications$: Observable<any>;

  private notification: BehaviorSubject<any>;
  public readonly notification$: Observable<any>;

  private errors: BehaviorSubject<any>;
  public readonly errors$: Observable<any>;

  private fetchNewBookings: BehaviorSubject<any>;
  public readonly fetchNewBookings$: Observable<any>;

  private polling;
  private pollingInterval = 120000;  // 120000 milisec = 2 min
  private pollingStarted = false;

  private dataStore: {
    notifications: any[],
    notification: any,
    fetchNewBookings: boolean,
  };

  constructor(
    private helperService: HelperService,
    private studiosService: StudiosService,
    private profileService: ProfileService,
  ) {

    this.notifications = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.notifications$ = this.notifications.asObservable();

    this.notification = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.notification$ = this.notification.asObservable();

    this.errors = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.errors$ = this.errors.asObservable();

    this.fetchNewBookings = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.fetchNewBookings$ = this.fetchNewBookings.asObservable();

    this.polling = interval(this.pollingInterval);

    this.dataStore = {
      notifications: [],
      notification: {},
      fetchNewBookings: false,
    };

  }

  startPolling() {
    if (!this.pollingStarted) {
      this.pollingStarted = true;
      this.fetchNotifications();
      this.polling.subscribe((data) => {
        return this.fetchNotifications();
      });
    }
  }

  fetchNotifications( onlyMine = true , unread = false ) {
    return this.helperService.getAction('/notifications' , {limit : 1000 , onlyMine : onlyMine , unread: unread} )
      .toPromise()
      .then((data) => {
        const oldBookingNotifications = this.dataStore.notifications.filter((notif) => notif.type === 'bookings').length;
        const newBookingNotifications = data.results.filter((notif) => notif.type === 'bookings').length;
        if (this.profileService.isStudio() && this.profileService.getOwnerStudio()) {
          this.dataStore.fetchNewBookings = (newBookingNotifications > oldBookingNotifications);
          this.fetchNewBookings.next(this.dataStore.fetchNewBookings);
        }
        this.dataStore.notifications = [...data.results];
        this.notifications.next(this.dataStore.notifications);
        return data;
      });
  }

  fetchNotification( notificationId: string ) {
    return this.helperService.getAction('/notifications/' + notificationId )
      .toPromise()
      .then((data) => {
        this.dataStore.notification = data;
        this.notification.next(this.dataStore.notification);
        return data;
      });
  }

  deleteNotification( notificationId: string ) {
    return this.helperService.deleteAction('/notifications/' + notificationId )
      .toPromise()
      .then((data) => {
        this.dataStore.notifications = this.dataStore.notifications.filter(notif => notif.id.toString() !== notificationId);
        this.notifications.next(this.dataStore.notifications);
        return data;
      });
  }

  readNotification( notificationId: string) {
    return this.helperService.postAction('/notifications/' + notificationId )
      .toPromise()
      .then((data) => {
        const foundNotification = this.dataStore.notifications.find((notif) => notif.id.toString() === notificationId);
        if (foundNotification) {
          foundNotification.read_by = data.read_by;
          this.notifications.next(this.dataStore.notifications);
        }
        this.dataStore.notification.read_by = data.read_by;
        this.notification.next(this.dataStore.notification);
        return data;
      });
  }

  readAllNotifications( notificationIds: string[]) {
    const readPromises = [];
    notificationIds.forEach((notificationId) => {
      readPromises.push(
        this.helperService.postAction('/notifications/' + notificationId )
          .toPromise()
      )
    });
    return Promise.all(readPromises).then((data) => {
      data.forEach((readNotification) => {
        const foundNotification = this.dataStore.notifications.find((notif) => notif.id.toString() === readNotification.id);
        if (foundNotification) {
          foundNotification.read_by = readNotification.read_by;
        }
      })
      this.notifications.next(this.dataStore.notifications);
      return data;
    })
  }

}
