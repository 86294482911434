<div class="landing-body">
<div class="landing-wrapper">

  <div class="header-content grid " style="margin-right: unset; margin-left: unset; color: #FFFFFF;">
    <div class="grid col-12 md:px-8 md:pt-8" style="margin-left: unset; margin-right: unset">
      <div class="col-12 md:col-6 pl-4 md:pl-8 text-left flex align-items-center ">
          <span class="welcomeMessage"><span class="keyword">STUDIO MANAGEMENT MADE EASY.</span></span>
      </div>
      <div class="pl-4 md:pl-0 col-12 md:col-5 md:col-offset-1 overflow-hidden">
        <p-image src="../../assets/images/landing/keyvisual_merged.png" alt="Image" width="100%"></p-image>
      </div>

      <div class="col-12 md:col-1 md:px-8 arrow-align">
        <button
          pButton
          icon="pi pi-arrow-down"
          class="arrow-scroll"
          (click)="scrollToEl(features)">
        </button>
      </div>

    </div>

    <div class="p-4 md:px-8 grid align-items-center">
      <section class = "px-4 md:px-8 what-is-bandbeat md:col-7">
        <p class = "what-is-bandbeat-header">
          <span class="keyword bold-text">Bandbeat</span> is a <span class="bold-text"> free management </span> tool
        </p>

        <p class = "what-is-bandbeat-header">
        for rehearsal & recording studios, and an <span class="bold-text"> online booking platform</span> for musicians.
        </p>

        <p class="pt-5 what-is-bandbeat-content">
          With modern and useful features, it helps you gain <span class="bold-text">full control</span> over your bookings, reduce endless, fruitless messages and phone calls with musicians, and reach
          a bigger audience with your own promotional <span class="bold-text">profile page</span>.
        </p>
      </section>

      <div class="video-container col-12 md:col-5 flex justify-content-center">
        <iframe class="w-full border-round-lg shadow-2"
                style="border: none"
                height="360"
                src="https://www.youtube.com/embed/olDxvDZwpBw?si=p4OXIUZl41atZSOJ"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
      </div>
    </div>

    <div #features id="features" class="col-12 p-4 md:p-8 md:pb-5">
      <section class="md:px-7 what-is-bandbeat col-12">
        <span class="features-title"><span class="keyword">Our <span class="bold-text">features</span></span></span>
        <div class="grid col-12 pt-8" style="display:inherit;">
          <p-dataView [value]="featuresItems" layout="grid">
            <ng-template let-feature pTemplate="gridItem">

              <div class="md:col-3 col-12 grid pt-3 md:pt-0 feature-card" style="text-align:center">
                <div class="col-3 md:col-12">
                  <img alt="Card" [src]="feature.image" style="position: sticky;" class="feature-image">
                </div>

                <div class="col-9 md:col-12">
                  <p class = "features-tiles" [innerHTML] = "feature.title"></p>
                </div>
              </div>
            </ng-template>
          </p-dataView>
        </div>
      </section>
    </div>

    <div class="col-12 text-center pb-8">
      <button
        pButton
        class="mr-3 sign-up-button"
        [routerLink]="['/signup']"
        label="Sign Up"></button>
    </div>

    <div id="pricing" class="col-12 pt-6 mb-8">
      <div style="margin-bottom: 3em; text-align: center">
        <h1 class="p-0 m-0"><span class="keyword">Our pricing</span></h1>
      </div>

      <div class="col-12 overflow-hidden">
        <p-image src="../../assets/images/landing/money.png" alt="Image"></p-image>
      </div>

      <div>
        <p class="pt-6 md:pt-0 pricing-sub-header bold-text">We’re getting paid only if you do!</p>
        <p class="pricing-simple-text">
          The registration and use of Bandbeat are free, and there’s a 10% commission
        <br>
          per booking made via the Bandbeat platform. *
        </p>
        <div class="mt-6 mb-8">
          <small>* Exceptions may apply. <a [routerLink]="'/dashboard/contact'" class="keyword">Contact us</a> for more information.</small>
        </div>
      </div>
    </div>

    <p-dialog [(visible)]="showCountryPromoDialog"
              [style]="{width: '450px'}"
              [modal]="true"
              class="promo-country-dialog"
              styleClass="p-fluid">
      <ng-template pTemplate="header">
        <div class="flex align-items-center gap-4">
          <i class="pi pi-exclamation-circle text-primary"></i>
          <span class="dialog-title">Exclusive Offer for US Studios</span>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <p class="dialog-message mb-4">
          🎉 <strong>Great news for US-based studios!</strong><br/>
        </p>
        <p class="dialog-message">
          Bandbeat is <strong>completely free</strong> for all studios in the <strong>United States for the entire 2025!</strong>
        </p>
        <p class="dialog-message">
          Join now and take your studio management to the next level.
        </p>
      </ng-template>

      <ng-template pTemplate="footer">
        <div class="text-center">
          <button pButton pRipple label="Got it!" icon="pi pi-check" class="p-button-text" (click)="showCountryPromoDialog = false"></button>
        </div>
      </ng-template>
    </p-dialog>
</div>
  </div>
</div>


