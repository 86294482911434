<div class="p-fluid grid formgrid">
  <div class="field col-12 md:col-6 relative">
    <span class="p-float-label">
      <input
        inputTrim
        id="studioName"
        type="text"
        pInputText
        [(ngModel)]="businessName" />
      <label style="color: #b1ede8 !important" for="studioName">Business name *</label>
    </span>
    <i pTooltip="The business legal name to be used for our contract and invoice agreements."
       class="pi pi-info-circle tooltip-icon"
       tooltipPosition="top"
       tooltipEvent="focus"
       tabindex="0"></i>
  </div>

  <div class="field col-12 md:col-6 relative">
    <span class="p-float-label">
      <input
        inputTrim
        id="studioVat"
        type="text"
        pInputText
        [(ngModel)]="studioVat" />
      <label style="color: #b1ede8 !important" for="studioVat">VAT/TIN number *</label>
    </span>
    <i pTooltip="Your VAT/TIN number is required for invoicing purposes."
       class="pi pi-info-circle tooltip-icon"
       tooltipPosition="top"
       tooltipEvent="focus"
       tabindex="0"></i>
  </div>

  <div class="field col-12 md:col-6">
    <span class="p-float-label">
      <input
        inputTrim
        pInputText
        [(ngModel)]="businessStreet"
        id="street"
        type="text"
        class="w-full" />
      <label style="color: #b1ede8 !important" for="street">Business street name *</label>
    </span>
  </div>
  <div class="field col-6 md:col-2">
    <span class="p-float-label">
      <input
        inputTrim
        pInputText
        [(ngModel)]="businessNumber"
        id="number"
        type="text"
        class="w-full" />
      <label style="color: #b1ede8 !important" for="number">Business street no. *</label>
    </span>
  </div>

  <div class="field col-6 md:col-4">
    <span class="p-float-label">
      <input
        inputTrim
        pInputText
        [(ngModel)]="businessZip"
        id="zip"
        type="text"
        class="w-full" />
      <label style="color: #b1ede8 !important" for="zip">Business zip code *</label>
    </span>
  </div>

  <div class="field col-12 md:col-6">
    <span class="p-float-label">
      <input
        inputTrim
        pInputText
        [(ngModel)]="businessCity"
        id="city"
        type="text"
        class="w-full" />
      <label style="color: #b1ede8 !important" for="city">Business city/state *</label>
    </span>
  </div>

  <div class="field col-6 md:col-3">
    <p-dropdown
      inputId="country"
      id="country"
      required="true"
      optionValue="label"
      [options]="countries"
      [(ngModel)]="businessCountry"
      (onChange)="selectCountry($event)"
      placeholder="Business country *">
      <ng-template let-country pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>
            <span>{{ country.emoji }}</span> {{ country.name }}
          </div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>

  <div class="field col-6 md:col-3">
    <p-dropdown
      inputId="currency"
      id="currency"
      required="true"
      optionValue="currency"
      optionLabel="currency"
      [options]="countries"
      [(ngModel)]="businessCurrency"
      (onChange)="selectCurrency($event)"
      placeholder="Currency *">
      <ng-template let-currency pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>
            <span>{{ currency.emoji }}</span> - {{ currency.currency }}
          </div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>

  <div class="col-6">
    <button
      pButton
      style="border-radius: 100px !important; width: 100%"
      pRipple
      label="Back"
      type="button"
      class="secondary-button p-button-raised p-button-rounded p-button-lg"
      (click)="goBack()">
    </button>
  </div>
  <div class="col-6">
    <button
      style="width: 100%"
      pButton
      pRipple
      label="Proceed"
      type="button"
      class="p-button-raised p-button-rounded p-button-lg"
      (click)="proceed()">
    </button>
  </div>
</div>

