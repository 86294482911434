import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { StudiosService } from '../../studios/studios.service';
import { HelperService } from '../../common/helper.service';
import { ProfileService } from '../../common/profile.service';
import { first } from 'rxjs/operators';
import { InstrumentAndEquipmentService } from '../../common/instruments.equipments.service';

@Component({
  selector: 'app-wizard-studio-rooms',
  templateUrl: './wizard-studio-rooms.component.html',
  styleUrls: ['./wizard-studio-rooms.component.scss'],
})
export class WizardStudioRoomsComponent implements OnInit {
  private studioId;

  public studio;

  public newRoomName;

  public disableNext = true;

  activeState: boolean[] = [];

  instruments;
  equipments;
  instrumentBrandMap = new Map<String, any[]>();
  equipmentBrandMap = new Map<String, any[]>();

  newInstrument;
  newInstrumentBrand;
  newInstrumentName;
  newInstrumentPrice;

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];
  services: any[] = [
    { name: 'Recording', code: 'recording' },
    { name: 'Rehearsal', code: 'rehearsal' },
    { name: 'Podcast', code: 'podcast' },
  ];
  availableServices: any[] = [];

  public businessCurrency: string;

  env = environment;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private studiosService: StudiosService,
    private instrumentAndEquipmentService: InstrumentAndEquipmentService,
    private helperService: HelperService,
    private profileService: ProfileService
  ) {
    this.studio = this.studiosService.studio$;
    this.businessCurrency = this.profileService.getOwnerStudio()
      .businessCurrency
      ? this.profileService.getOwnerStudio().businessCurrency
      : 'EUR';
  }

  ngOnInit(): void {
    this.instruments = this.instrumentAndEquipmentService.getInstruments();
    this.equipments = this.instrumentAndEquipmentService.getEquipments();
    this.instrumentBrandMap =
      this.instrumentAndEquipmentService.getInstrumentsMap();
    this.equipmentBrandMap =
      this.instrumentAndEquipmentService.getEquipmentMap();

    this.studioId = this.profileService.getOwnerStudio().id;
    this.studiosService.fetchStudio(this.studioId, true).then((studioData) => {
      this.disableNext = !(studioData.rooms.length > 0);
    });
  }

  addNewRoom() {
    const newRoom: any = {};
    newRoom.name = this.newRoomName;
    if (newRoom.name === '' || newRoom.name === undefined) {
      this.messageService.add({
        key: 'globalToast',
        severity: 'error',
        summary: 'Error',
        detail: 'You need to enter a room name!',
        life: 3000,
      });
      return;
    }
    this.studiosService.createRoom(this.studioId, newRoom).then((data) => {
      this.updateLocalData(data);
      this.newRoomName = null;
      this.messageService.add({
        key: 'globalToast',
        severity: 'success',
        summary: 'Successful',
        detail: 'Room Added',
        life: 3000,
      });
      this.disableNext = false;
      this.activeState[0] = true;
      this.buildAvailableServices({ index: 0 });
    });
  }

  updateLocalData(studio) {
    this.helperService.saveLocalStorage('studio', studio);
    this.profileService.setOwnerStudio(studio);
  }

  async imageUpload(event, type, entity, roomId?, roomUploader?) {
    if (entity === 'room') {
      this.studiosService
        .addRoomImage(this.studioId, roomId, event.files[0], type)
        .then((data) => {
          this.updateLocalData(data);
          this.messageService.add({
            key: 'globalToast',
            severity: 'success',
            summary: 'Successful',
            detail: 'Image Uploaded',
            life: 3000,
          });
        })
        .catch((err) => {
          if (roomUploader) {
            roomUploader.clear();
          }
          const error = err.error;
          if (error.code === 500 && error.message === 'File too large') {
            this.messageService.add({
              key: 'globalToast',
              severity: 'error',
              summary: 'Error',
              detail: 'File is over 4mb!',
              life: 3000,
            });
          }
        });
    }
  }

  removeImage(image, entity, roomId?) {
    if (entity === 'room') {
      this.studiosService
        .deleteRoomImage(this.studioId, roomId, image.id)
        .then((data) => {
          this.updateLocalData(data);
          this.messageService.add({
            key: 'globalToast',
            severity: 'success',
            summary: 'Successful',
            detail: 'Image Removed',
            life: 3000,
          });
        });
    }
  }

  public buildAvailableServices(event) {
    this.studio.pipe(first()).subscribe((studioData) => {
      const room = studioData.rooms[event.index];
      this.availableServices = [
        { name: 'Recording', code: 'recording' },
        { name: 'Rehearsal', code: 'rehearsal' },
        { name: 'Podcast', code: 'podcast' },
      ];
      let services = [];
      services = Object.assign(services, this.availableServices);
      services.forEach((service) => {
        if (room.services.find((e) => e.type === service.code)) {
          this.availableServices.splice(
            this.availableServices.indexOf(service),
            1
          );
        }
      });
    });
  }

  addNewService(room) {
    const newService: any = {};
    if (room.newServiceType == null) {
      this.messageService.add({
        key: 'globalToast',
        severity: 'warn',
        summary: 'Warning',
        detail: 'You need to select a service!',
        life: 3000,
      });
      return;
    }

    newService.type = room.newServiceType;
    newService.price = room.newServicePrice;
    this.studiosService
      .createService(this.studioId, room.id, newService)
      .then((data) => {
        this.updateLocalData(data);
        room.newServiceType = null;
        room.newServicePrice = null;
        this.buildAvailableServices({
          index: Object.keys(this.activeState).find(
            (el) => this.activeState[el]
          ),
        });

        this.messageService.add({
          key: 'globalToast',
          severity: 'success',
          summary: 'Successful',
          detail: 'Service Added',
          life: 3000,
        });
      });
  }

  resetNewEquipmentBrand(room: any) {
    if (
      !room.newEquipment?.brands.some(
        (brand) => brand.type === room.newEquipmentBrand?.type
      )
    ) {
      room.newEquipmentBrand = null;
    }
  }

  addNewEquipment(room) {
    if (
      room.newEquipmentName === null ||
      room.newEquipmentName.match(/^\s*$/) !== null
    ) {
      this.messageService.add({
        key: 'globalToast',
        severity: 'error',
        summary: 'Error',
        detail: 'Equipment name is required!',
        life: 3000,
      });
    } else if (
      !room.newEquipmentDescription ||
      room.newEquipmentDescription.match(/^\s*$/) !== null
    ) {
      this.messageService.add({
        key: 'globalToast',
        severity: 'error',
        summary: 'Error',
        detail: 'Equipment description is required!',
        life: 3000,
      });
    } else {
      const newEquipment: any = {};
      newEquipment.name = room.newEquipmentName;
      newEquipment.category = room.newEquipment?.type;
      newEquipment.brand = room.newEquipmentBrand?.type;
      newEquipment.description = room.newEquipmentDescription;
      this.studiosService
        .createEquipment(this.studioId, room.id, newEquipment)
        .then((data) => {
          room.newEquipmentName = null;
          room.newEquipment = null;
          room.newEquipmentBrand = null;
          room.newEquipmentDescription = null;
          this.messageService.add({
            key: 'globalToast',
            severity: 'success',
            summary: 'Successful',
            detail: 'Room Equipment Added',
            life: 3000,
          });
        });
    }
  }

  previousPage() {
    this.router.navigate(['studio-hours'], { relativeTo: this.route.parent });
  }

  nextPage(skip = false) {
    if (!skip) {
      this.router.navigate(['studio-rentals'], {
        relativeTo: this.route.parent,
      });
    } else {
      this.router.navigate(['studio-rentals'], {
        relativeTo: this.route.parent,
      });
    }
  }
}
