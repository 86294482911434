import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {DashboardDemoComponent} from './theme/demo/view/dashboarddemo.component';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './theme/pages/app.notfound.component';
import {AppErrorComponent} from './theme/pages/app.error.component';
import {AppAccessdeniedComponent} from './theme/pages/app.accessdenied.component';
import {DashboardAnalyticsComponent} from './theme/demo/view/dashboardanalytics.component';
import {LoginComponent} from './authentication/login/login.component';
import {SignupComponent} from './authentication/signup/signup.component';
import {UsersComponent} from './users/users.component';
import {AuthenticatedGuard} from './common/guards/authenticated.guard';
import {StudiosComponent} from './studios/studios.component';
import {BandsComponent} from './bands/bands.component';
import {UserComponent} from './users/user/user.component';
import {BandComponent} from './bands/band/band.component';
import {NewBookingComponent} from './bookings/new-booking/new-booking.component';
import {BookingTypeComponent} from './bookings/new-booking/booking-type/booking-type.component';
import {BookingStudioComponent} from './bookings/new-booking/booking-studio/booking-studio.component';
import {BookingInfoComponent} from './bookings/new-booking/booking-info/booking-info.component';
import {BookingConfirmComponent} from './bookings/new-booking/booking-confirm/booking-confirm.component';
import {NewbookingGuard} from './common/guards/newbooking.guard';
import {StudioComponent} from './studios/studio/studio.component';
import {BookingsComponent} from './bookings/bookings.component';
import {BookingComponent} from './bookings/booking/booking.component';
import { StudioBookingsComponent } from "./studios/studio/studio-bookings/studio-bookings.component";
import { StudioCalendarComponent } from "./studios/studio/studio-calendar/studio-calendar.component";
import { StudioProfileComponent } from "./studios/studio/studio-profile/studio-profile.component";
import { StudioSettingsComponent } from "./studios/studio/studio-settings/studio-settings.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { StudioPasswordComponent } from "./studios/studio/studio-password/studio-password.component";
import { BookingSummaryComponent } from "./bookings/booking-summary/booking-summary.component";
import { LandingComponent } from "./landing/landing.component";
import { LandingGuard } from "./common/guards/landing.guard";
import {ContactComponent} from "./footer/contact/contact.component";
import {TcComponent} from "./footer/tc/tc.component";
import {PrivacyAndPolicyComponent} from "./footer/privacy-and-policy/privacy-and-policy.component";
import {VerificationPendingComponent} from "./authentication/verification-pending/verification-pending.component";
import {StudioDeletionCompleteComponent} from "./studios/studio/studio-deletion-complete/studio-deletion-complete.component";
import { ForgotPasswordComponent } from "./authentication/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./authentication/reset-password/reset-password.component";
import { ReviewsComponent } from "./reviews/reviews.component";
import { ReportsComponent } from "./reports/reports.component";
import {WizardStepComponent} from "./common/cards/wizard-step/wizard-step.component";
import {SetupWizardComponent} from "./setup-wizard/setup-wizard.component";
import {WizardStudioInfoComponent} from "./setup-wizard/wizard-studio-info/wizard-studio-info.component";
import {WizardStudioMediaComponent} from "./setup-wizard/wizard-studio-media/wizard-studio-media.component";
import {WizardStudioHoursComponent} from "./setup-wizard/wizard-studio-hours/wizard-studio-hours.component";
import {WizardStudioRoomsComponent} from "./setup-wizard/wizard-studio-rooms/wizard-studio-rooms.component";
import {WizardStudioRentalsComponent} from "./setup-wizard/wizard-studio-rentals/wizard-studio-rentals.component";
import {GoogleRedirectComponent} from "./authentication/google-redirect/google-redirect.component";

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: 'dashboard', component: AppMainComponent,
                children: [
                    // {
                    //   path: '',
                    //   component: DashboardDemoComponent,
                    //   canActivate: [AuthenticatedGuard, UserTypeGuard]
                    // },
                  {
                    path: '',
                    component: LandingComponent,
                    canActivate: [LandingGuard ],
                  },


                  {
                    path: 'partner',
                    canActivate: [AuthenticatedGuard],
                    children: [
                      {
                        path: '',
                        redirectTo: 'bookings',
                        pathMatch: 'full'
                      },
                      {
                        path: 'bookings',
                        canActivate: [AuthenticatedGuard],
                        children: [
                          {
                            path: '',
                            component: StudioBookingsComponent,
                            canActivate: [AuthenticatedGuard]
                          },
                          {
                            path: ':bookingId',
                            component: BookingSummaryComponent,
                            canActivate: [AuthenticatedGuard]
                          }
                        ]
                      },
                      {
                        path: 'calendar',
                        component: StudioCalendarComponent,
                        canActivate: [AuthenticatedGuard]
                      },
                      {
                        path: 'studio',
                        component: StudioProfileComponent,
                        canActivate: [AuthenticatedGuard]
                      },
                      {
                        path: 'profile',
                        canActivate: [AuthenticatedGuard],
                        children: [
                          {
                            path: '',
                            component: StudioSettingsComponent,
                            canActivate: [AuthenticatedGuard]
                          },
                          {
                            path: 'newpassword',
                            component: StudioPasswordComponent,
                            canActivate: [AuthenticatedGuard]
                          },

                        ]
                      },
                      {
                        path: 'notifications',
                        canActivate: [AuthenticatedGuard],
                        children: [
                          {
                            path: '',
                            component: NotificationsComponent,
                            canActivate: [AuthenticatedGuard]
                          }
                        ]
                      },
                      {
                        path: 'wizard',
                        canActivate: [AuthenticatedGuard],
                        children: [
                          {
                            path: '',
                            component: SetupWizardComponent,
                            canActivate: [AuthenticatedGuard],
                            children: [
                              {
                                path: 'studio-info',
                                component: WizardStudioInfoComponent,
                                canActivate: [AuthenticatedGuard]
                              },
                              {
                                path: 'studio-media',
                                component: WizardStudioMediaComponent,
                                canActivate: [AuthenticatedGuard]
                              },
                              {
                                path: 'studio-hours',
                                component: WizardStudioHoursComponent,
                                canActivate: [AuthenticatedGuard]
                              },
                              {
                                path: 'studio-rooms',
                                component: WizardStudioRoomsComponent,
                                canActivate: [AuthenticatedGuard]
                              },
                              {
                                path: 'studio-rentals',
                                component: WizardStudioRentalsComponent,
                                canActivate: [AuthenticatedGuard]
                              },
                            ]
                          },
                        ]
                      }
                    ]
                  },

                  {
                    path: 'admin',
                    canActivate: [AuthenticatedGuard],
                    children: [



                      {
                        path: '',
                        redirectTo: 'reports',
                        pathMatch: 'full'
                      },

                      {
                        path: 'analytics',
                        component: DashboardAnalyticsComponent,
                        canActivate: [AuthenticatedGuard]
                      },

                      {
                        path: 'users',
                        canActivate: [AuthenticatedGuard],
                        children: [
                          {
                            path: '',
                            component: UsersComponent,
                            canActivate: [AuthenticatedGuard]
                          },
                          {
                            path: ':id',
                            component: UserComponent,
                            canActivate: [AuthenticatedGuard]
                          },
                          {
                            path: ':id/bookings/:bookingId',
                            component: BookingSummaryComponent,
                            canActivate: [AuthenticatedGuard]
                          },
                          {
                            path: ':id/newbooking',
                            component: NewBookingComponent,
                            canActivate: [AuthenticatedGuard]
                          },
                          {
                            path: ':id/restartbooking',
                            component: NewBookingComponent,
                            canActivate: [AuthenticatedGuard]
                          }
                        ]
                      },
                      {
                        path: 'studios',
                        canActivate: [AuthenticatedGuard],
                        children: [
                          {
                            path: '',
                            component: StudiosComponent,
                            canActivate: [AuthenticatedGuard]
                          },
                          {
                            path: ':id',
                            component: StudioComponent,
                            canActivate: [AuthenticatedGuard]
                          },
                          {
                            path: ':id/bookings/:bookingId',
                            component: BookingSummaryComponent,
                            canActivate: [AuthenticatedGuard]
                          }
                        ]
                      },
                      {
                        path: 'bands',
                        canActivate: [AuthenticatedGuard],
                        children: [
                          {
                            path: '',
                            component: BandsComponent,
                            canActivate: [AuthenticatedGuard]
                          },
                          {
                            path: ':id',
                            component: BandComponent,
                            canActivate: [AuthenticatedGuard]
                          },
                          {
                            path: ':id/newbooking',
                            component: NewBookingComponent,
                            canActivate: [AuthenticatedGuard]
                          },
                          {
                            path: ':id/bookings/:bookingId',
                            component: BookingSummaryComponent,
                            canActivate: [AuthenticatedGuard]
                          }
                        ]
                      },
                      {
                        path: 'notifications',
                        canActivate: [AuthenticatedGuard],
                        children: [
                          {
                            path: '',
                            component: NotificationsComponent,
                            canActivate: [AuthenticatedGuard]
                          }
                        ]
                      },
                      {
                        path: 'bookings',
                        canActivate: [AuthenticatedGuard],
                        children: [
                          {
                            path: '',
                            component: BookingsComponent,
                            canActivate: [AuthenticatedGuard]
                          },
                          {
                            path: ':bookingId',
                            component: BookingSummaryComponent,
                            canActivate: [AuthenticatedGuard]
                          },
                        ]
                      },
                      {
                        path: 'profile',
                        canActivate: [AuthenticatedGuard],
                        children: [
                          {
                            path: '',
                            component: StudioSettingsComponent,
                            canActivate: [AuthenticatedGuard]
                          },
                          {
                            path: 'newpassword',
                            component: StudioPasswordComponent,
                            canActivate: [AuthenticatedGuard]
                          },

                        ]
                      },
                      {
                        path: 'reviews',
                        canActivate: [AuthenticatedGuard],
                        children: [
                          {
                            path: '',
                            component: ReviewsComponent,
                            canActivate: [AuthenticatedGuard]
                          }
                        ]
                      },
                      {
                        path: 'reports',
                        canActivate: [AuthenticatedGuard],
                        children: [
                          {
                            path: '',
                            component: ReportsComponent,
                            canActivate: [AuthenticatedGuard]
                          }
                        ]
                      }
                    ]
                  },
                  {path: 'contact', component: ContactComponent},
                  {path: 'terms&conditions', component: TcComponent},
                  {path: 'privacy&policy', component: PrivacyAndPolicyComponent},
                ]
            },
            {path: 'error', component: AppErrorComponent},
            {path: 'access', component: AppAccessdeniedComponent},
            {path: 'notfound', component: AppNotfoundComponent},
            {path: 'login', component: LoginComponent},
            {path: 'signup', component: SignupComponent},
            {path: 'verificationpending', component: VerificationPendingComponent},
            {path: 'studiodeletioncomplete', component: StudioDeletionCompleteComponent},
            {path: 'forgotpassword', component: ForgotPasswordComponent},
            {path: 'resetpassword', component: ResetPasswordComponent},
            {path: 'google-oauth-success', component: GoogleRedirectComponent },
          {
            path: '',
            redirectTo: 'dashboard',
            pathMatch: 'full'
          },
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule],
    providers: [
      AuthenticatedGuard,
      NewbookingGuard
    ]
})
export class AppRoutingModule {
}
