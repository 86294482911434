import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  bookDate;
  onboardingCountries = ['us'];
  showCountryPromoDialog = false;
  featuresItems = [
    {
      image: '../../assets/images/landing/profile.png',
      title: 'Studio <br> <strong>profile page</strong>',
    },
    {
      image: '../../assets/images/landing/notes.png',
      title: 'Detailed <br> <strong>booking requests</strong>',
    },
    {
      image: '../../assets/images/landing/calendar.png',
      title: 'Standalone <br> <strong>automated calendar</strong>',
    },
    {
      image: '../../assets/images/landing/lock2.png',
      title: '<strong>Secure online payment</strong> <br> & <strong>cancellation</strong> policy feature',
    },
    {
      image: '../../assets/images/landing/rating.png',
      title: 'Internal <strong>review /<br> rating system</strong>',
    },
    {
      image: '../../assets/images/landing/analytics.png',
      title: 'Monthly <br><strong>analytics</strong>',
    },
    {
      image: '../../assets/images/landing/control.png',
      title: '<strong>Total control</strong> over bookings,<br>prices, discounts',
    },
  ];

  constructor(private http: HttpClient,
              private localSt: LocalStorageService) {
    this.getLocationEstimate();
  }

  ngOnInit(): void {}

  scrollToEl(target: HTMLElement){
    target.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
  }

  getLocationEstimate(): void {
    if (!this.localSt.retrieve('country')) {
      this.http.get<any>('https://api.ipgeolocation.io/ipgeo?apiKey=9b319d60ae79466ea16993dca8405216').subscribe(
        (response) => {
          this.localSt.store('country', response.country_code2);
          this.shouldShowCountryPromoDialog();
        },
        (error) => {
          console.error(error);
        }
      );
      return;
    }
    this.shouldShowCountryPromoDialog();
  }

  shouldShowCountryPromoDialog() {
    if (this.onboardingCountries.includes(this.localSt.retrieve('country').toLowerCase())) {
      setTimeout(() => {
        this.showCountryPromoDialog = true;
      }, 2000);
      return;
    }
    this.showCountryPromoDialog = false;
  }

}
