import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {StudiosService} from '../../studios.service';
import {BookingsService} from '../../../bookings/bookings.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {filter, map, skip, takeUntil} from "rxjs/operators";
import {ProfileService} from "../../../common/profile.service";
import { CalendarOptions } from '@fullcalendar/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HelperService} from "../../../common/helper.service";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {InstrumentAndEquipmentService} from '../../../common/instruments.equipments.service';
import {Subject} from "rxjs";
import {NotificationsService} from "../../../notifications/notifications.service";

@Component({
  selector: 'app-studio-calendar',
  templateUrl: './studio-calendar.component.html',
  styleUrls: ['./studio-calendar.component.scss'],
  providers: [ConfirmationService]
})
export class StudioCalendarComponent implements OnInit, OnChanges, OnDestroy {

  @Input() studioId: string;

  events: any[];
  recurringEvents: any[];
  options: CalendarOptions;

  offlineBookingDialog = false;
  bookingDetailDialog = false;
  offlineBookingEditDialog = false;

  // Calendar sync variables
  calendarSyncWizardDialog = false;
  calendarSyncWizardStep = 1;
  calendarSyncWizardButtonLabel = 'Next';
  selectedCalendarSyncType: 'single' | 'multiple' | null = null;
  studioCalendarSyncType: 'single' | 'multiple' | null = null;
  availableGoogleCalendars: any[] = [];
  selectedSingleGoogleCalendar: string | null = null;
  availableCalendarColors: any[] = [];
  showSyncConfigDialog = false;
  isSyncCooldownActive = false;
  syncCooldownDuration = 5; // minutes
  loading = false;
  calendarViewType: string;
  calendarViewStartDate: string;
  calendarViewEndDate: string;
  fetchNewBookings: any;

  submitted = false;
  bookingLabel = '';
  bookDate;
  bookTime;
  public currentDate;
  public maxDate;
  bookNote = '';
  bookRoom;
  duration = 1;
  //minTime;

  studioBookings;
  onDestroy$: Subject<void> = new Subject();

  selectedRooms: any[] = [];

  availableRooms = [];
  availableRoomsForAppointment = [];

  detailedBooking;

  availabilityOptions;
  timeAvailability = new Map<number, any[]>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private studiosService: StudiosService,
    private bookingsService: BookingsService,
    private instrumentAndEquipmentService: InstrumentAndEquipmentService,
    private helperService: HelperService,
    private profileService: ProfileService,
    private notificationsService: NotificationsService,
  ) {
    this.studioBookings = this.studiosService.bookings$.pipe(filter(bks => Object.keys(bks).length !== 0));
  }



  ngOnChanges(changes: SimpleChanges): void {
    this.studioId = changes.studioId.currentValue;
    if (changes.hasOwnProperty('studioId') && changes.studioId.currentValue) {
      this.fetchData();
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.bookDate = new Date();
    this.currentDate = new Date();
    this.maxDate = new Date();
    this.maxDate.setMonth(this.maxDate.getMonth() + 3);
    this.studioBookings.pipe(skip(1), takeUntil(this.onDestroy$)).subscribe((bookings) => {
      this.recurringEvents = [];
      this.events = bookings.results
        .filter((booking) => booking.status !== 'studio-declined' && booking.status !== 'studio-canceled' && booking.status !== 'user-declined' && booking.status !== 'user-canceled' && booking.status !== 'user-not-found' && booking.reported !== true)
        .map((booking, index) => {
          const title = this.getCalendarEventName(booking);
          const event = {
            id : booking.id,
            title,
            start : booking.bookingDate,
            end : new Date( Date.parse(booking.bookingDate) + booking.duration * 60 * 60 * 1000).toISOString(),
            booking,
            color : '#29303C',
            className: ''
          };
          if (booking.bookingType === 'offline') {
            event.color = '#4B4B4B';
            event.title = this.getCalendarEventName(booking);
          }
          if (booking.status === 'pending-confirmation') {
            event.className = 'pendingEvent';
          }

          if (booking.recurringDates &&
            booking.recurringDates.length &&
            booking.status === 'pending-confirmation') {
            booking.recurringDates.slice(1).forEach((recurringDate, index) => {
              const recurringEvent = Object.assign({} , event);
              recurringEvent.start = recurringDate;
              recurringEvent.end = new Date( Date.parse(recurringDate) + booking.duration * 60 * 60 * 1000).toISOString()
              recurringEvent.id = recurringEvent.id + '_' + index;
              this.recurringEvents.push(recurringEvent);
            });
          }

          return event;
        });
      this.events.push(...this.recurringEvents);
      this.options = {...this.options, ...{events : [...this.events]}};
      this.createAvailability(this.profileService.getOwnerStudio().availability);
    });

    const initialDate = this.route.snapshot.queryParamMap.get('initialDate');

    if (this.profileService.isStudio()) {
      this.studioId = this.profileService.getOwnerStudio().id;
      this.availableRooms = this.profileService.getOwnerStudio().rooms
        .map((room, index) => { return {name: room.name, code : room.id, amount : 0, color : this.getColor(index)} })
      this.selectedRooms = this.availableRooms.map((room) => room.code);
      this.availableRoomsForAppointment = [...this.availableRooms, {name: 'All' , code: -1}];
      this.fetchData();
    }

    this.options = {
      plugins:[dayGridPlugin,timeGridPlugin,interactionPlugin],
      initialDate: initialDate ? Date.parse(initialDate) : Date.now(),
      scrollTime: this.getStartTime(initialDate),
      headerToolbar: {
        // left: 'prev title next',
        left: this.helperService.isMobile() ? '' : 'prev title next',
        // center: '',
        center: this.helperService.isMobile() ? 'prev title next' : '' ,
        // right: 'timeGridDay,timeGridWeek,dayGridMonth'
        right: this.helperService.isMobile() ? '' : 'timeGridDay,timeGridWeek,dayGridMonth'
      },
      initialView: 'timeGridWeek',
      allDaySlot: false,
      editable: false,
      eventDurationEditable: false,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      slotLabelFormat: {hour: 'numeric', minute: '2-digit', hour12: false},
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
      dayHeaderContent: this.generateHeader,
      /*slotMinTime: this.minTime,*/
      firstDay: 1,
      nowIndicator: true,
      eventClick: (e) => {
        this.loading = true;
        this.bookingsService.fetchBooking(e.event.id).then(booking => {
          this.detailedBooking = booking;
          if (booking.members.length > 0) {
            booking.primaryMusicRoles = booking.members
              .map(member => booking.team.members.find(mbr => mbr.user.id === member.member.id))
              .map(member => member?.user?.musicRoles.find(role => role._id === member.musicRole))
              .map(mr => mr?.type ? [this.instrumentAndEquipmentService.getMusicRolesMap().get(mr.type)?.label] : [])
              .filter((roles) => roles.length > 0);
          } else {
            booking.primaryMusicRoles = booking.booker?.musicRoles
              .filter(role => role.primary)
              .map(mr => this.instrumentAndEquipmentService.getMusicRolesMap().get(mr.type)?.label)
              .filter((roles) => roles.length > 0);
          }
          if (this.detailedBooking.bookingType === 'offline') {
            this.showEdit(this.detailedBooking);
          } else {
            this.bookingDetailDialog = true;
          }
        }).finally(() => this.loading = false);
      },
      eventDidMount: (arg) => {
        const bookingEvent = arg.event.extendedProps.booking;
        if (bookingEvent && bookingEvent.room) {
          let roomRibbon = document.createElement('div')
          let firstChild = arg.el.firstChild;
          roomRibbon.style.position = 'absolute';
          roomRibbon.style.left = '0';
          roomRibbon.style.height = '100%';
          roomRibbon.style.width = '0.5em';
          const room = this.availableRooms.find((room) => room.code === bookingEvent.room.id);
          if (room) {
            roomRibbon.style.backgroundColor = room.color;
          }
          arg.el.insertBefore(roomRibbon, firstChild);
        }
      },
      select: (e) => {
        this.bookTime = this.helperService.formatDate(e.startStr , 'H:mm'  );
        this.duration = this.helperService.timeDifference(e.start , e.end  );
        if (this.duration === 0) {
          this.duration = 1;
        }
        this.bookDate = e.start;
        this.buildAvailabilityOptions();
        this.showNew();
      },
      datesSet: (e) => {
        this.calendarViewType = e.view.type;
        this.calendarViewStartDate = e.startStr;
        this.calendarViewEndDate = e.endStr;
        if (e.view.type === 'timeGridWeek' || e.view.type === 'timeGridDay') {
          // fetch weekly bookings
          this.fetchData(e.startStr);
        } else if (e.view.type === 'dayGridMonth') {
          // fetch monthly bookings
          this.fetchData(e.startStr, e.endStr);
        }
      },
    };

    this.route.queryParams.subscribe((params) => {
      const initialCalendarSyncSetup = params['setup-calendar-sync'];
      if (initialCalendarSyncSetup) {
        this.startSyncWizard();
      }
    });
    this.refreshSyncState();

    this.fetchNewBookings = this.notificationsService.fetchNewBookings$.pipe(
      takeUntil(this.onDestroy$),
      map(fetchNew => {
        if (fetchNew) {
          this.studiosService.fetchStudioBookings(this.studioId,
            this.calendarViewStartDate,
            (this.calendarViewType === 'dayGridMonth') ? this.calendarViewEndDate : null);
        }
      }));

    this.loading = false;
  }

  buildAvailabilityOptions(){
    this.availabilityOptions = [];
    this.availabilityOptions  = Object.assign([], this.timeAvailability.get(this.bookDate.getDay()));

    const regex = new RegExp(':', 'g');

    if (this.bookDate === this.currentDate) {
      this.timeAvailability.get(this.bookDate.getDay()).forEach(slot => {
        if (parseInt(slot.replace(regex, ''), 10) < parseInt(this.bookDate.getHours().toString() + this.bookDate.getMinutes(), 10)) {
          this.availabilityOptions.splice(this.availabilityOptions.indexOf(slot), 1);
        }
      });
    }

    if (!this.availabilityOptions.includes(this.bookTime)){
      this.bookTime = null;
    }
  }

  generateHeader( date ) {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    if (date.view.type === 'dayGridMonth') {
      return { html: '<span>'+ days[date.date.getDay()] +'</span> ' }

    } else if (date.view.type === 'timeGridWeek') {
      return { html: '<span>'+  date.date.toString().split(' ')[0]  +'</span> <br> <span style="font-weight: initial !important">'+ date.date.getDate() + '</span> ' }
    } else if (date.view.type === 'timeGridDay') {
      return { html: '<span>'+ days[date.date.getDay()] +'</span> ' }
    }
    return { html: '<span>'+ date.date.toString().split(' ')[0] +'</span> ' }
  }

  buildAvailabilitySlots(availableMinTime: Date, availableMaxTime: Date) {
    const slots = [];

    const minTime = new Date(this.currentDate);
    const maxTime = new Date(this.currentDate);

    minTime.setHours(new Date(availableMinTime).getHours(), new Date(availableMinTime).getMinutes());
    maxTime.setHours(new Date(availableMaxTime).getHours(), new Date(availableMaxTime).getMinutes());
    if (maxTime < minTime) {
      maxTime.setDate(maxTime.getDate() + 1);
    }

    for (const date = minTime; date < maxTime; date.setMinutes(date.getMinutes() + 60)){
      slots.push(date.getHours() + ':' +  String(date.getMinutes()).padStart(2, '0'));
    }
    return slots;
  }



  createAvailability( studioAvailability ) {

    const businessHours = [];
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    days.forEach((day, index) => {

      if (studioAvailability[day].available) {

        const dayAvailability = {
          daysOfWeek: [index],
          startTime: this.helperService.formatDate(studioAvailability[day].from, 'HH:mm'),
          endTime: this.helperService.formatDate(studioAvailability[day].to, 'HH:mm'),
        };

        this.timeAvailability.set(index, this.buildAvailabilitySlots(studioAvailability[day].from, studioAvailability[day].to));

        /*        if (index === 1 || this.helperService.formatDate(studioAvailability[day].from, 'HH:mm') < this.minTime) {
                  this.minTime = this.helperService.formatDate(studioAvailability[day].from, 'HH:mm');
                }*/

        if (dayAvailability.endTime.startsWith('0')) {
          dayAvailability.endTime = [`${parseInt(dayAvailability.endTime.split(':')[0], 10) + 24}`, dayAvailability.endTime.split(':')[1]].join(':');
        }
        businessHours.push(dayAvailability);
      }
    });

    if (businessHours.length > 0) {
      this.options = {...this.options, ...{businessHours : businessHours}};
      this.options = {...this.options, ...{selectConstraint : businessHours}};
    }
  }

  fetchData(week?: string, monthEnd?: string) {
    this.loading = true;
    this.studiosService.fetchStudioBookings(this.studioId, week, monthEnd)
      .then(_ => {})
      .catch(e => {})
      .finally(() => {
        this.loading = false;
      });
  }

  addBooking(): void {
    if (!this.bookTime) {
      this.messageService.add({
        key: 'globalToast',severity: 'error', summary: 'Error', detail: 'Please select Book Time', life : 3000});
      return;
    }
    if (this.duration > 6) {
      this.duration = 6;
      this.messageService.add({
        key: 'globalToast',severity: 'error', summary: 'Error', detail: 'Duration should be up to 6 hours!', life : 3000});
      return;
    }
    let bookings = [];
    if (this.bookRoom === -1 ) {
      this.availableRooms.forEach((availableRoom) => {
        bookings.push(
          {
            studio : this.studioId,
            bookingType : 'offline',
            bookingDate : new Date(this.bookDate.getFullYear(), this.bookDate.getMonth(), this.bookDate.getDate(), this.bookTime.substring(0,this.bookTime.indexOf(':')), this.bookTime.substring(this.bookTime.indexOf(':')+1), 0),

            duration: this.duration,
            room: availableRoom.code,
            // notes: ['N/A Time'],
            label: 'N/A'
          }
        )
      })

    } else {
      bookings.push(
        {
          studio : this.studioId,
          bookingType : 'offline',
          bookingDate : new Date(this.bookDate.getFullYear(), this.bookDate.getMonth(), this.bookDate.getDate(), this.bookTime.substring(0,this.bookTime.indexOf(':')), this.bookTime.substring(this.bookTime.indexOf(':')+1), 0),

          duration: this.duration,
          room: this.bookRoom,
          // notes: ['N/A Time'],
          label: 'N/A'
        }
      )
    }
    Promise.all(bookings.map(newBooking => this.bookingsService.createBooking(newBooking)))
      .then((data) => {
        this.fetchData();
        this.messageService.add({
          key: 'globalToast',severity: 'success', summary: 'Success', detail: 'Booking Added', life: 3000});
      });

    this.hideDialog();
  }

  editBooking(): void {
    if (!this.bookTime) {
      this.messageService.add({
        key: 'globalToast',severity: 'error', summary: 'Error', detail: 'Please select Book Time', life : 3000});
      return;
    }
    if (this.duration > 6) {
      this.duration = 6;
      this.messageService.add({
        key: 'globalToast',severity: 'error', summary: 'Error', detail: 'Duration should be up to 6 hours!', life : 3000});
      return;
    }

    this.bookingsService.editBooking( this.detailedBooking.id ,
        {
          bookingDate : new Date(this.bookDate.getFullYear(), this.bookDate.getMonth(), this.bookDate.getDate(), this.bookTime.substring(0,this.bookTime.indexOf(':')), this.bookTime.substring(this.bookTime.indexOf(':')+1), 0),
          duration: this.duration,
          room: this.bookRoom,
        }
      )
      .then((data) => {
        this.messageService.add({
          key: 'globalToast',severity: 'success', summary: 'Success', detail: 'You have edited this booking', life: 3000});
        this.hideDetails();
        this.studiosService.fetchStudioBookings(this.studioId, this.calendarViewStartDate, (this.calendarViewType === 'dayGridMonth') ? this.calendarViewEndDate : null);
      });

  }


  hideDialog() {
    this.duration = 1;
    this.bookingLabel = '';
    this.bookNote = '';
    this.bookRoom = null;
    this.bookDate = undefined;
    this.offlineBookingDialog = false;
    this.offlineBookingEditDialog = false;

    this.submitted = false;
  }


  showNew(initBookTime = false): void {
    this.offlineBookingDialog = true;
    if (initBookTime) {
      this.bookDate = this.currentDate;
      this.bookTime = null;
      this.buildAvailabilityOptions();
    }
  }

  showEdit(bookToEdit): void {
    this.bookTime = this.helperService.formatDate( bookToEdit.bookingDate , 'H:mm'  );
    this.duration = bookToEdit.duration;
    if (this.duration === 0) {
      this.duration = 1;
    }
    this.bookDate = new Date(bookToEdit.bookingDate);
    this.bookRoom = bookToEdit.room.id;
    this.buildAvailabilityOptions();

    this.offlineBookingEditDialog = true;
  }

  getColor(index: number) {
    // const availableColors: any[] = ['#3CD1C5' , '#6C4796', '#61B23B' , '#F8941E', '#F2B8B5' , '#18544F' , '#C4B5D5' ]
    const availableColors: any[] = [ '#6C4796', '#61B23B' , '#F8941E', '#F2B8B5' , '#18544F' , '#C4B5D5' ]
    while (index >= availableColors.length) {
      index = index - availableColors.length;
    }
    return availableColors[index];
  }


  hideDetails() {
    this.bookingDetailDialog = false;
    this.offlineBookingEditDialog = false;
  }

  confirmBooking(booking, accept = true, force = false): void {
    let newStatus = '';
    if (booking.status === 'pending-confirmation' && accept) {
      newStatus = 'studio-confirmed';
    } else if (booking.status === 'pending-confirmation' && !accept) {
      newStatus = 'studio-declined';
    } else if (booking.status === 'studio-confirmed' && !accept) {
      newStatus = 'studio-canceled';
    } else if (accept) {
      newStatus = 'studio-confirmed';
    }
    if (newStatus === 'studio-declined') {
      this.confirmationService.confirm({
        message: 'Are you sure you want to reject this booking request?',
        header: 'Confirm Booking Rejection',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.bookingsService.editBooking(booking.id , { status : newStatus })
            .then((data) => {
              this.messageService.add({
                key: 'globalToast',severity: 'success', summary: 'Success', detail: 'You have declined this booking', life: 3000});
              this.hideDetails();
              this.studiosService.fetchStudioBookings(this.studioId, this.calendarViewStartDate, (this.calendarViewType === 'dayGridMonth') ? this.calendarViewEndDate : null);
            });
        },
        reject: () => {
          this.hideDetails();
        }
      });
    } else if (newStatus === 'studio-canceled') {
      this.confirmationService.confirm({
        message: 'Are you sure you want to cancel this upcoming booking?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.bookingsService.editBooking(booking.id , { status : newStatus })
            .then((data) => {
              this.messageService.add({
                key: 'globalToast',severity: 'success', summary: 'Success', detail: 'You have canceled this booking', life: 3000});
              this.hideDetails();
              this.studiosService.fetchStudioBookings(this.studioId, this.calendarViewStartDate, (this.calendarViewType === 'dayGridMonth') ? this.calendarViewEndDate : null);
            });
        },
        reject: () => {
          this.hideDetails();
        }
      });
    } else {
      this.bookingsService.editBooking(booking.id , { status : newStatus , force })
        .then((data) => {
          this.hideDetails();
          this.studiosService.fetchStudioBookings(this.studioId, this.calendarViewStartDate, (this.calendarViewType === 'dayGridMonth') ? this.calendarViewEndDate : null);
        })
        .catch((err) => {
          if (err.error.message === "Booking slot is allready taken") {
            this.confirmationService.confirm({
              message: 'You have already accepted a conflicting booking request, are you sure you want to continue?',
              header: 'Confirm',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                return this.confirmBooking(booking, accept , true);
              }
            });
          }
        });
    }

  }

  reportBooking(booking) : void {

    if (
      booking.status === 'studio-confirmed' &&
      !(this.helperService.isAfter(new Date(booking.bookingDate))) &&
      booking.reported !== true
    ) {

      this.confirmationService.confirm({
        message: 'Are you sure you want to report No-Show from the customer?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.bookingsService.editBooking(booking.id , { reported : true })
            .then((data) => {
              this.messageService.add({
                key: 'globalToast',severity: 'success', summary: 'Success', detail: 'You have reported this booking', life: 3000});

              this.hideDetails();
              this.studiosService.fetchStudioBookings(this.studioId, this.calendarViewStartDate, (this.calendarViewType === 'dayGridMonth') ? this.calendarViewEndDate : null);
            });
        }
      });
    } else {
      this.messageService.add({
        key: 'globalToast',severity: 'warn', summary: 'Warning', detail: 'This booking hasnt passed yet!', life: 3000});
      this.hideDetails();
    }
  }

  removeOfflineBooking(booking = this.detailedBooking) {
    this.loading = true;
    this.bookingsService.deleteBooking(booking.id)
      .then((data) => {
        this.messageService.add({
          key: 'globalToast',severity: 'success', summary: 'Success', detail: 'You have removed this Unavailable time', life: 3000});

        this.hideDetails();
        this.studiosService.fetchStudioBookings(this.studioId, this.calendarViewStartDate, (this.calendarViewType === 'dayGridMonth') ? this.calendarViewEndDate : null);
      }).finally(() => {this.loading = false; });
  }

  getStartTime(initialDate) {
    if (initialDate) {
      const bookingDate = new Date(initialDate);
      const hours = (bookingDate.getHours() - 1 >= 0 ? bookingDate.getHours() - 1 : 0);
      const minutes = bookingDate.getMinutes();
      const startTime = '' + (hours > 9 ? hours : '0' + hours ) + ':' + ( minutes > 9 ? minutes : '0' + minutes) + ':00';
      return startTime;
    } else {
      return '06:00:00';
    }

  }

  startGoogleAuth() {
    window.location.href = this.studiosService.startGoogleCalendarSync(this.studioId);
  }

  startSyncWizard(): void {
    this.resetWizard();
    this.calendarSyncWizardDialog = true;
  }

  resetWizard(): void {
    this.calendarSyncWizardStep = 1;
    this.selectedCalendarSyncType = null;
    this.selectedSingleGoogleCalendar = null;
    this.availableRooms.forEach((room) => {
      room.selectedCalendar = null;
    });
  }

  nextStep(): void {
    if (this.calendarSyncWizardStep === 1 && this.selectedCalendarSyncType) {
      this.fetchAvailableCalendars();
      this.calendarSyncWizardStep++;
    } else if (this.calendarSyncWizardStep === 2) {
      this.calendarSyncWizardStep++;
      if (this.selectedCalendarSyncType === 'single') {
        this.fetchCalendarColors();
      }
      if (this.selectedCalendarSyncType === 'multiple') {
        this.calendarSyncWizardButtonLabel = 'Save & Sync';
      }
    } else if (this.calendarSyncWizardStep === 3) {
      if (this.selectedCalendarSyncType === 'single') {
        this.calendarSyncWizardStep++;
        this.calendarSyncWizardButtonLabel = 'Save & Sync';
      } else {
        this.finalizeSyncSetup();
      }
    } else if (this.calendarSyncWizardStep === 4) {
      this.finalizeSyncSetup();
    }
  }

  previousStep(): void {
    if (this.calendarSyncWizardStep > 1) {
      this.calendarSyncWizardStep--;
      this.calendarSyncWizardButtonLabel = 'Next';
    }
  }

  fetchAvailableCalendars(): void {
    this.loading = true;
    this.studiosService.fetchAvailableGoogleCalendars(this.studioId).subscribe(
      (calendars) => {
        this.loading = false;
        this.availableGoogleCalendars = calendars.map((calendar: any) => ({
          id: calendar.id,
          summary: (calendar.summaryOverride ? calendar.summaryOverride : calendar.summary),
          description: calendar.description,
          timezone: calendar.timeZone,
          accessRole: calendar.accessRole,
          backgroundColor: calendar.backgroundColor,
          primary: calendar.primary
        }));
      },
      (calendarFetchError) => {
        this.loading = false;
        this.messageService.add({
          key: 'globalToast', severity: 'error', summary: 'Error', detail: 'Could not get Google Calendar: ' + calendarFetchError.message,
        });
      }
    );
  }

  fetchCalendarColors(): void {
    this.loading = true;
    this.studiosService.fetchAvailableGoogleColors(this.studioId, this.selectedSingleGoogleCalendar).subscribe(
      (colors) => {
        const defaultCalendarColor = colors.defaultCalendarColor;
        // Format colors for dropdown
        this.availableCalendarColors = colors.eventColors.map((color: any) => ({
          colorId: color.colorId,
          colorValue: color.colorValue,
        }));
        this.availableCalendarColors.unshift({
          colorId: defaultCalendarColor.colorId,
          colorValue: defaultCalendarColor.colorValue,
        });
        this.loading = false;
      },
      (fetchError) => {
        this.messageService.add({
          key: 'globalToast', severity: 'error', summary: 'Error', detail: 'Could not get Calendar details: ' + fetchError.message,
        });
        this.loading = false;
      }
    );
  }

  getCalendarName(calendarId: string): string {
    const calendar = this.availableGoogleCalendars.find((cal) => cal.id === calendarId);
    return calendar ? calendar.summary : 'Unknown Calendar';
  }

  areAllRoomsColorMapped(): boolean {
    return this.availableRooms.every((room) =>
      this.availableCalendarColors.some((color) => color.colorValue === room?.googleSyncColor?.colorValue)
    );
  }

  finalizeSyncSetup(): void {
    const syncData =
      this.selectedCalendarSyncType === 'single'
        ? {
          type: 'single',
          roomMappings: this.availableRooms.map((room) => ({
            calendarId: this.selectedSingleGoogleCalendar,
            roomId: room.code,
            colorId: room.googleSyncColor.colorId,
            colorValue: room.googleSyncColor.colorValue,
          })),
        }
        : {
          type: 'multiple',
          roomMappings: this.availableRooms.map((room) => ({
            roomId: room.code,
            calendarId: room.selectedCalendar,
            colorId: '0', // color defaults
            colorValue: '#000000',
          })),
        };
    this.saveSyncSetup(syncData);
    this.calendarSyncWizardDialog = false;
  }

  saveSyncSetup(syncData: any): void {
    this.router.navigate([], {
      queryParams: {
        'setup-calendar-sync': null,
      },
      queryParamsHandling: 'merge'
    });
    this.loading = true;
    this.studiosService.saveSyncSetup(this.studioId, syncData).then(
      (data) => {
        this.loading = false;
        this.resetWizard();
        this.profileService.setOwnerStudio(data);
        this.refreshSyncState(data);
        this.runCalendarSync(true);
      },
      (saveSyncError) => {
        this.messageService.add({
          key: 'globalToast', severity: 'error', summary: 'Error', detail: 'Sync setup failed: ' + saveSyncError.message, life: 3000});
        this.loading = false;
      }
    );
  }

  refreshSyncState(studioState?: any): void {
    if (!studioState) {
      studioState = this.profileService.getOwnerStudio();
    }
    this.studioCalendarSyncType = studioState.calendarSyncType;
    this.availableRooms.forEach(room => {
      const syncData = studioState.rooms.find(r => r.id === room.code);
      room.calendarId = syncData.calendarSync?.calendarId;
      room.eventColor = syncData.calendarSync?.eventColor?.colorValue;
    });
  }

  areAllRoomsMapped(): boolean {
    return this.availableRooms.every((room) => !!room.selectedCalendar);
  }

  runCalendarSync(fullSync: boolean): void {
    this.loading = true;
    this.studiosService.executeCalendarSync(this.studioId, fullSync)
      .toPromise()
      .then((data) => {
        this.activateSyncCooldown();
        this.studioCalendarSyncType = this.profileService.getOwnerStudio().calendarSyncType;
        this.fetchData();
        const message = data.responses.length === 0 ? 'Everything in sync' : `Synced ${data.responses.length} new bookings from your Google Calendar`;
        this.messageService.add({
          key: 'globalToast', severity: 'success', summary: 'Success', detail: message, life: 3000
        });
      })
      .catch(err => this.messageService.add({
        key: 'globalToast', severity: 'error', summary: 'Error', detail: 'Sync failed: ' + err.message, life: 3000})
      )
      .finally(() => this.loading = false);
  }

  activateSyncCooldown() {
    this.isSyncCooldownActive = true;
    // reset cooldown after 5 minutes
    setTimeout(() => {
      this.isSyncCooldownActive = false;
    }, this.syncCooldownDuration * 60 * 1000);
  }

  getCalendarEventName(booking: any): string {
    if (booking.bookingType === 'offline') {
      return booking.label?.toLowerCase().startsWith('gcal:')
        ? '[Google] ' + booking.label.split('/')[1]
        : 'Unavailable time @ ' + booking.room.name;
    }
    return `${this.helperService.toTitleCase(booking.service.type)} @ ${booking.room.name}`;
  }

  showSyncConfiguration(): void {
    this.showSyncConfigDialog = true;
    const syncSettings = this.profileService.getOwnerStudio();
    this.availableRooms.forEach(room => {
      const syncData = syncSettings.rooms.find(r => r.id === room.code);
      room.calendarId = syncData.calendarSync.calendarId;
      room.eventColor = syncData.calendarSync.eventColor.colorValue;
    });
  }

  closeSyncConfiguration(): void {
    this.showSyncConfigDialog = false;
  }

  editSyncSettings(): void {
    this.showSyncConfigDialog = false;
    this.resetWizard();
    this.startSyncWizard();
  }

  configureSyncOptions(): void {
    // todo implement reconfiguration of sync options
  }

  revokeGoogleAuth(): void {
    this.confirmationService.confirm({
      message: 'This action will stop the sync with your Google Calendar. Are you sure you want to continue?',
      header: 'Unlink Google Calendar Sync',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true;
        this.studiosService.revokeGoogleAuth(this.studioId)
          .then((data) => {
            this.profileService.setOwnerStudio(data);
            this.studioCalendarSyncType = null;
            this.messageService.add({
              key: 'globalToast',
              severity: 'success',
              summary: 'Success',
              detail: 'Google Calendar sync has been stopped.',
              life: 3000
            });
          })
          .catch((err) => {
            this.messageService.add({
              key: 'globalToast',
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to revoke Google Calendar sync: ' + err.message,
              life: 3000,
            });
          })
          .finally(() => {
            this.loading = false;
          });
      },
      reject: (() => {})
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.studiosService.resetStudioBookings();
  }

}
