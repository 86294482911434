import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HelperService} from "../common/helper.service";

@Injectable({
  providedIn: 'root'
})
export class BandsService {


  private bands: BehaviorSubject<any>;
  public readonly bands$: Observable<any>;

  private band: BehaviorSubject<any>;
  public readonly band$: Observable<any>;

  private bandMembers: BehaviorSubject<any>;
  public readonly bandMembers$: Observable<any>;

  private invites: BehaviorSubject<any>;
  public readonly invites$: Observable<any>;

  private bookings: BehaviorSubject<any>;
  public readonly bookings$: Observable<any>;

  private ratings: BehaviorSubject<any>;
  public readonly ratings$: Observable<any>;

  private errors: BehaviorSubject<any>;
  public readonly errors$: Observable<any>;

  private dataStore: {
    bands: any,
    band: any,
    bandMembers: any,
    invites: any,
    bookings: any,
    ratings: any,
  };

  constructor( private helperService: HelperService ) {

    this.bands = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.bands$ = this.bands.asObservable();

    this.band = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.band$ = this.band.asObservable();

    this.bandMembers = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.bandMembers$ = this.bandMembers.asObservable();

    this.invites = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.invites$ = this.invites.asObservable();

    this.bookings = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.bookings$ = this.bookings.asObservable();

    this.ratings = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.ratings$ = this.ratings.asObservable();

    this.errors = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.errors$ = this.errors.asObservable();

    this.dataStore = {
      bands: {
        results : []
      },
      band: {

      },
      bandMembers: [],
      invites: [],
      bookings: [],
      ratings: []
    };
  }


  fetchBands() {
    return this.helperService.getAction('/teams'  )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.bands, data);
        this.bands.next(data);
        return data;
      });
  }


  addBand( newBand: any ) {

    return this.helperService.postAction('/teams' , newBand )
      .toPromise()
      .then((data) => {
        this.dataStore.bands.results = this.dataStore.bands.results.concat([data]);
        this.bands.next(this.dataStore.bands);
        return data;
      });
  }

  deleteBand( bandId: string ) {
    return this.helperService.deleteAction('/teams/' + bandId )
      .toPromise()
      .then((data) => {
        this.dataStore.bands.results = this.dataStore.bands.results.filter(std => std.id !== bandId);
        this.bands.next(this.dataStore.bands);
        return data;
      });
  }

  editBand( editedBand: any) {
    const editBody: any = {};
    Object.assign(editBody, editedBand);
    delete editBody.id;
    return this.helperService.patchAction('/teams/' + editedBand.id , editBody )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.bands.results.find(std => std.id === editedBand.id) , data);
        this.bands.next(this.dataStore.bands);
        return data;
      });
  }

  fetchBand( bandId: string , withMembers = false ) {
    return this.helperService.getAction('/teams/' + bandId , {withMembers} )
      .toPromise()
      .then((data) => {
        Object.assign(this.dataStore.band, data);
        this.band.next(data);
        return data;
      });
  }

  fetchBandMembers( bandId: string ) {
    return this.helperService.getAction('/teams/' + bandId + '/members' )
      .toPromise()
      .then((data) => {
        this.dataStore.bandMembers = [ ...data ];
        this.bandMembers.next(data);
        return data;
      });
  }

  fetchBandInvites( bandId: string ) {
    return this.helperService.getAction('/teams/' + bandId + '/invites' )
      .toPromise()
      .then((data) => {
        this.dataStore.invites = [ ...data.results ];
        this.invites.next(data);
        return data;
      });
  }

  fetchBandBookings( bandId: string ) {
    return this.helperService.getAction('/teams/' + bandId + '/bookings' )
      .toPromise()
      .then((data) => {
        data.results = data.results.map(el => this.mapInternalData(el));
        this.dataStore.bookings = [ ...data.results ];
        this.bookings.next(data);
        return data;
      });
  }

  addBandMember(bandId: string , userId: string) {
    return this.helperService.patchAction('/teams/' + bandId + '/members' , {userId} )
      .toPromise()
      .then((data) => {
        return this.fetchBand(bandId);
      });
  }

  inviteBandMember(bandId: string , userId: string) {
    return this.helperService.postAction('/invites' , {user : userId, team: bandId} )
      .toPromise()
      .then((data) => {
        return this.fetchBandInvites(bandId);
      });
  }

  inviteBandMemberByMail(bandId: string , userMail: string) {
    return this.helperService.postAction('/invites' , {email : userMail, team: bandId} )
      .toPromise()
      .then((data) => {
        return this.fetchBandInvites(bandId);
      });
  }

  removeMember(bandId: string , userId: string) {
    return this.helperService.deleteAction('/teams/' + bandId + '/members/' + userId  )
      .toPromise()
      .then((data) => {
        return this.fetchBand(bandId);
      });
  }

  deleteInvite(inviteId: string) {
    return this.helperService.deleteAction('/invites/' + inviteId  )
      .toPromise()
      .then((data) => {
        return data;
      });
  }

  fetchBandRatings(bandId: string) {
    return this.helperService.getAction('/reviews/' , { team : bandId, category : 'partner', limit : 100000})
      .toPromise()
      .then((data) => {
        this.dataStore.ratings = [ ...data.results ];
        this.ratings.next(data);
        return data;
      })
  }


  mapInternalData( booking: any) {
    if (typeof booking.studio !== 'string') {
      if (booking.hasOwnProperty('room') && typeof booking.room === 'string') {
        booking.room = booking.studio.rooms.find((room) => room.id === booking.room);
      }
      if (booking.hasOwnProperty('service') && typeof booking.service === 'string') {
        booking.service = booking.room.services.find((service) => service.id === booking.service);
      }
      if (booking.hasOwnProperty('instruments') && booking.instruments.length > 0) {
        booking.instruments = booking.instruments.map((instrument) => {
          return booking.studio.instruments.find((inst) => inst.id === instrument);
        });
      }
    }
    return booking;
  }


}
