import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../authentication.service';
import {MessageService} from 'primeng/api';
import {NotificationsService} from '../../notifications/notifications.service';
import {UserService} from '../../users/user/user.service';
import {HelperService} from '../../common/helper.service';
import { ContractsService } from '../contract/contracts.service';
import { environment } from '../../../environments/environment';
import {LocalStorageService} from 'ngx-webstorage';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  userName: string;
  userEmail: string;
  userPassword: string;
  userConfirmPassword: string;
  userRole = 'partner';
  userPhoneNumber: number;
  termsAndConditionAccepted = false;
  newsletterAccepted = true;
  loading = false;
  studioName: string;
  studioVat: string;

  roles: any[] = [
    {name: 'Admin' , code: 'admin'},
    {name: 'Partner' , code: 'partner'},
    {name: 'User' , code: 'user'}
  ];

  phonePrefix = '';
  public businessCity: string;
  public businessCountry: string;
  public businessName: string;
  public businessNumber: string;
  public businessStreet: string;
  public businessZip: string;
  public businessCurrency: string;

  public showLogin = true;

  public registrationStep = 'user';

  public countries: any[];

  public env = environment;

  constructor(
    private router: Router,
    private notificationsService: NotificationsService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private contractsService: ContractsService,
    private messageService: MessageService,
    private helperService: HelperService,
    private localStorage: LocalStorageService,
  ) {
    this.countries = this.helperService.getCountriesData();
  }

  ngOnInit(): void {
    if (this.localStorage.retrieve('country')) {
      const visitingCountry = this.countries.find((c) => c.code.toLowerCase() === this.localStorage.retrieve('country').toLowerCase());
      this.businessCountry = visitingCountry.label;
      this.phonePrefix = visitingCountry.phone;
    }
  }

  public goBack(event ) {
    if (this.registrationStep === 'contract') {
      this.registrationStep = 'business';
    } else if (this.registrationStep === 'business') {
      this.registrationStep = 'user';
    }
  }

  public proceed(event? ) {
    if (event === 'business') {
      this.showLogin = false;
      if (this.userConfirmPassword !== this.userPassword) {
        if(!this.userPassword){
          this.messageService.clear();
          this.messageService.add(
            {
              severity: 'error',
              summary: 'No password',
              detail: 'You need to enter a secure password to proceed.'
            });
        }else{
          this.messageService.clear();
          this.messageService.add(
            {
              severity: 'error',
              summary: 'Wrong data',
              detail: 'The entered passwords do not match.'
            });
          }
      } else if (!this.phonePrefix) {
        this.messageService.clear();
        this.messageService.add(
          {
            severity: 'error',
            summary: 'Wrong data',
            detail: 'You need to select your country code.'
          });
      }else if (!this.termsAndConditionAccepted) {
        this.messageService.clear();
        this.messageService.add(
          {
            severity: 'error',
            summary: 'Wrong data',
            detail: 'You need to accept our Terms & Conditions to proceed.'
          });
      } else if (!this.userPhoneNumber ||
        !this.userEmail ||
        !this.userName ||
        !this.studioName) {
        this.messageService.clear();
        this.messageService.add(
          {
            severity: 'error',
            summary: 'Wrong data',
            detail: 'All fields are required.'
          });
      } else if (this.userPassword.length < 8 || ( !this.userPassword.match(/\d/) || !this.userPassword.match(/[a-zA-Z]/) ) ) {
        this.messageService.clear();
        this.messageService.add(
          {
            severity: 'error',
            summary: 'Weak Password ',
            detail: 'Password must be at least 8 characters, containing at least a letter and a number.'
          });
      } else {
        this.userService.getUserExistence(this.userEmail).then((data) => {
            if (data.exists) {
              this.messageService.clear();
              this.messageService.add(
                {
                  severity: 'error',
                  summary: 'User Exists',
                  detail: 'The provided email is already in use.'
                });
              } else {
                this.messageService.clear();
                this.registrationStep = event;
              }
          }).catch((error) => {
            if (error.error.message === `"usermail" must be a valid email`) {
              this.messageService.clear();
              this.messageService.add(
                {
                  severity: 'error',
                  summary: 'Invalid Email',
                  detail: 'Please enter a valid email address.'
                });
            }
          });
        }
    } else if (event === 'contract') {
      if (!this.businessZip.match(/^[a-zA-Z0-9 ]+$/)) {
        this.messageService.clear();
        this.messageService.add(
          {
            severity: 'error',
            summary: 'Zip code is wrong',
            detail: 'Zip code must contain only letters and numbers.'
          });
      } else {
        this.messageService.clear();
        this.registrationStep = event;
      }
    }
  }

  public signup(event) {
      const studioData = {
        name : this.studioName,
        vat : this.studioVat,
        businessCurrency: this.businessCurrency,
      };
      let userData;

      this.loading = true;
      this.authenticationService.registerUser(
        this.userName,
        this.businessCountry,
        this.businessCity,
        this.businessZip,
        this.businessStreet,
        this.businessNumber,
        this.userEmail,
        this.userPassword,
        this.userPhoneNumber,
        this.phonePrefix,
        this.userRole,
        this.termsAndConditionAccepted,
        this.businessName,
        studioData)
        .then((data) => {
          if (this.env.contractsEnabled) {
            return this.contractsService.createNewContract()
              .then((contractData) => {
                return Promise.resolve(data);
              });
          } else {
            return Promise.resolve(data);
          }
        }).then((data) => {
          userData = data.user;
          return (this.newsletterAccepted) ?
            this.userService.addRegistration(userData.id, {event : 'partner_newsletter'}) :
            Promise.resolve(data);
        })
        .then((data) => {
          return (userData.role === 'partner') ?
            this.userService.addRegistration(userData.id, {event : 'partner_wizard'}) :
            Promise.resolve(data);
        })
        .then((data) => {
          this.loading = false;
          this.notificationsService.startPolling();
          this.router.navigate(['verificationpending']);
          setTimeout(() => {
            this.messageService.add(
              {
                key: 'globalToast',
                severity: 'success',
                summary: 'Successful registration',
                detail: 'Welcome back ' + userData.name + ', dont forget to verify your email!'
              });
          }, 1000);
        }).catch((err) => {
          this.loading = false;
          if (this.env.contractsEnabled) {
            this.registrationStep = 'user';
          }
          this.messageService.clear();
          this.messageService.add({ severity: 'error', summary: 'Something went wrong', detail: err.error.message});
      });
  }

  public selectCountry(event) {
    const selected = this.countries.find(country => this.businessCountry === country.label);
    this.phonePrefix = event.value;
  }

  getUserExistence(){
    const user = this.userService.getUserExistence(this.userEmail);

    return user;
  }

}
