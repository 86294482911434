import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import { differenceInHours, format, isAfter, isBefore } from "date-fns";
import { LocalStorageService } from "ngx-webstorage";

import * as countriesList from 'countries-list';
import { toTitleCase } from "codelyzer/util/utils";
import * as FileSaver from 'file-saver';

import currencyToSymbolMap from 'currency-symbol-map/map';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  private applicationState = 'admin';

  private instrumentTypes: BehaviorSubject<any>;
  public readonly instrumentTypes$: Observable<any>;



  private dataStore: {
    instrumentTypes: any
  };

  constructor(
    private localSt: LocalStorageService,
    private http: HttpClient,
    private router: Router
  ) {

    this.instrumentTypes = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.instrumentTypes$ = this.instrumentTypes.asObservable();


    this.dataStore = {
      instrumentTypes: {
      }
    };
  }



  /**
   * ----------------------------
   * HTTP REQUEST RELATED FUNCTIONS
   * ----------------------------
   */

  createHeaders(acceptType?: string, contentType?: string) {
    let headers = new HttpHeaders();
    if (acceptType && acceptType !== '') {
      headers = headers.set('Accept', acceptType);
    }
    if (contentType) {
      headers = headers.set('Content-Type', contentType);
    }
    // headers = headers.set('Content-Type' , 'application/octet-stream')
    // headers = headers.set('Content-Disposition' , 'attachment; filename="filename.jpg"; filename*="filename.jpg')
    return headers;
  }

  getAction(endpoint: string, parameters?: any, acceptType?: string, contentType?: string) {
    const httpOptions: any = {
      headers: this.createHeaders(acceptType, contentType),
      params: parameters,
    };
    if (acceptType && (acceptType === 'application/text')) {
      httpOptions.responseType = 'text';
    }

    if (acceptType && (acceptType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
      httpOptions.responseType = 'blob';
    }

    return this.http.get(
      environment.backendUrl + endpoint,
      httpOptions
    ) as Observable<any>;
  }

  postAction(endpoint: string,
             data?: any,
             acceptType?: string,
             contentType?: string
  ) {

    const httpOptions: any = {
      headers: this.createHeaders(acceptType, contentType)
    };
    return this.http.post(
      environment.backendUrl + endpoint,
      data,
      httpOptions
    ) as Observable<any>;
  }

  putAction(endpoint: string, acceptType?: string, contentType?: string, parameters?: HttpParams) {
    const httpOptions: any = {
      headers: this.createHeaders(acceptType, contentType),
      params: parameters,
    };
    return this.http.put(
      environment.backendUrl + endpoint,
      httpOptions
    ) as Observable<any>;
  }

  patchAction(endpoint: string,
              data?: any,
              acceptType?: string,
              contentType?: string
  ) {
    const httpOptions: any = {
      headers: this.createHeaders(acceptType, contentType)
    };
    return this.http.patch(
      environment.backendUrl + endpoint,
      data,
      httpOptions
    ) as Observable<any>;
  }

  deleteAction(endpoint: string, acceptType?: string, contentType?: string, parameters?: HttpParams) {
    const httpOptions: any = {
      headers: this.createHeaders(acceptType, contentType),
      params: parameters,
    };
    return this.http.delete(
      environment.backendUrl + endpoint,
      httpOptions
    ) as Observable<any>;
  }

  setApplicationState(state: string) {
    this.applicationState = state;
  }

  getApplicationState() {
    return this.applicationState;
  }

  openGMaps(type: string = 'dir' , coordinates?: any) {
    let googleURl = 'https://www.google.com/maps/' + type + '/?api=1';
    if (coordinates) {
      googleURl = googleURl + '&query=' + coordinates.x + '%2C' + coordinates.y;
    }
    window.open(googleURl);
  }

  navigateTo(navigationPath: string) {
    return this.router.navigateByUrl( navigationPath);
  }

  fetchInstrumentTypes() {
    if (Object.keys(this.dataStore.instrumentTypes).length > 0) {
      return Promise.resolve(this.dataStore.instrumentTypes);
    } else {
      return this.getAction('/instruments' )
        .toPromise()
        .then((data) => {
          Object.assign(this.dataStore.instrumentTypes, data);
          this.instrumentTypes.next(data);
          return data;
        });
    }
  }

  isAfter(dateOne: Date , dateTwo = new Date){
    return isAfter( dateOne , dateTwo)
  }

  isBefore(dateOne: Date , dateTwo = new Date){
    return isBefore( dateOne , dateTwo)
  }

  formatDate(date: string, dateFormat?) {
    if (!dateFormat) {
      dateFormat = 'yyyy-MM-dd hh:mm';
    }
    const dateValue = new Date(date);
    return format(dateValue , dateFormat)
  }

  timeDifference( dateOne: Date, dateTwo: Date) {
    return differenceInHours(dateTwo, dateOne);
  }

  toTitleCase(title: string) {
    return toTitleCase(title.replace('_', ' '));
  }

  saveLocalStorage( key: string, data: any) {
    this.localSt.store(key, data);
  }


  isMobile() {
    return window.innerWidth < 768;
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  getCountriesData() {
    const countryCodes = Object.keys(countriesList.countries);

    return countryCodes.map((code) => {
      return {
        code,
        value : code,
        name : countriesList.countries[code].name,
        label : countriesList.countries[code].name,
        emoji : countriesList.countries[code].emoji,
        emojiU : countriesList.countries[code].emojiU,
        phone : countriesList.countries[code].phone.includes(',') ? countriesList.countries[code].phone.split(',')[0] : countriesList.countries[code].phone,
        currency : countriesList.countries[code].currency.includes(',') ? countriesList.countries[code].currency.split(',')[0] : countriesList.countries[code].currency,
      };
    })
      // .filter(el => !el.phone.includes(','))
      // .filter(el => !el.currency.includes(','))
      .sort((a, b) => a.name > b.name ? 1 : -1);
  }

  saveToFile( content , fileName , type? ) {
    const blob = new Blob([content], {type: type ? type : "text/plain;charset=utf-8"});
    FileSaver.saveAs(blob, fileName ? fileName : "download.txt");
  }

  getCurrencySymbol(currency: string) {
    if ( currencyToSymbolMap[currency] ) {
      return currencyToSymbolMap[currency];
    } else {
      return currency;
    }
  }

  getGoogleCalendarSyncEndpoint(studioId: string): string {
    return environment.backendUrl + '/auth/google-calendar-sync?studio=' + studioId;
  }


}
