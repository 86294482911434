import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from "primeng/api";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationsService } from "../../../notifications/notifications.service";
import { ProfileService } from "../../../common/profile.service";
import { UserService } from "../../../users/user/user.service";
import {StudiosService} from "../../studios.service";
import {map} from "rxjs/operators";
import {combineLatest} from 'rxjs';
import {HelperService} from '../../../common/helper.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-studio-settings',
  templateUrl: './studio-settings.component.html',
  styleUrls: ['./studio-settings.component.scss'],
  providers: [ConfirmationService]
})
export class StudioSettingsComponent implements OnInit {

  public env = environment;
  public user;
  private userRegistrations = [];

  private userId;
  private userData;
  public userNewsletter = true;
  public phonePrefix = '(+) ';
  public countries: any[];
  public businessCurrency;

  public settingsTypes = [
    {name: 'Account', code: 'account' ,  icon : 'account_circle', info: 'Your account settings'},
  ];
  public selectedSettingType = this.settingsTypes[0].code;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationsService: NotificationsService,
    private messageService: MessageService,
    private profileService: ProfileService,
    private userService: UserService,
    private studioService: StudiosService,
    private confirmationService: ConfirmationService,
    private helperService: HelperService
  ) {

    if (this.env.paymentEnabled && this.env.paymentEnabled === true) {
      this.settingsTypes.push(
        {name: 'Payments', code: 'payments' , icon : 'payments', info: 'Your payments history'},
        {name: 'Reports', code: 'reports' , icon : 'payments', info: 'Your reports'},
      )
    }

    this.countries = this.helperService.getCountriesData();
    this.userId = this.profileService.getUserData().id;

    this.user = combineLatest(
      this.userService.user$,
      this.userService.registrations$
    ).pipe(map(data=>{
      const userData = data[0];
      this.userRegistrations = data[1];
      if (!userData.hasOwnProperty('address') ) {
        userData.address = {
          street : null,
          number : null,
          zip : null,
          city : null,
          country : null
        }
      }
      if (userData.countryCode) {
        this.phonePrefix = '(+' + userData.countryCode +') ';
      }
      if (userData.address.country) {
        this.selectCountry({value : userData.address.country});
      }
      this.userData = userData;
      this.userNewsletter = !!this.userRegistrations.find((sub) => sub.event === 'partner_newsletter');
      return userData;
    }));

    this.userService.fetchUser(this.userId);
    this.userService.fetchUserRegistrations(this.userId);

    if ( this.profileService.getOwnerStudio() && this.profileService.getOwnerStudio().businessCurrency) {
      this.businessCurrency = this.profileService.getOwnerStudio().businessCurrency;
    } else {
      this.studioService.fetchStudio(this.profileService.getOwnerStudio().id).then((studioData) => {
        this.businessCurrency = studioData.businessCurrency;
      })
    }


  }

  ngOnInit(): void {
  }

  saveChanges() {
    if ( this.userNewsletter !== !!this.userRegistrations.find((sub) => sub.event === 'partner_newsletter') )   {
      if (this.userNewsletter) {
        this.userService.addRegistration(this.userId, {event : 'partner_newsletter'});
      } else {
        this.userService.removeRegistrationByType(this.userId, 'partner_newsletter');
      }
    }
    const editedUser: any = {
      email : this.userData.email ,
      name : this.userData.name ,
      lastName : this.userData.lastName ,
      phoneNumber : this.userData.phoneNumber ,
    }
    Object.keys(editedUser).forEach((key) => {
      if ( !editedUser[key] || (editedUser[key] + '').length === 0) {
        delete editedUser[key];
      }
    })
    this.userService.editUser( this.userId, editedUser)
      .then((data) => {
        this.profileService.setUserData(data);
        this.messageService.add({
          key: 'globalToast',
          severity: 'success',
          summary: 'Confirmed',
          detail: 'Your account settings are saved!',
          life: 3000
        });
      })
      .catch((err) => {
        this.messageService.add({
          key: 'globalToast', severity: 'error', summary: 'Error', detail: err.error.message, life: 3000});
        throw err;
      });
  }

  toChangePassword() {
    this.router.navigate(
      ['newpassword'],
      { relativeTo: this.route}
    );
  }

  deleteAccount(){
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete your Studio Account?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.studioService.deleteStudio(this.profileService.getOwnerStudio().id)
        this.profileService.logOutUser();
        this.router.navigate(['studiodeletioncomplete']);
      }
    });
  }

  public selectCountry(event) {
    const selected = this.countries.find(country => event.value === country.label);
    // this.phonePrefix = '(+' + selected.phone + ') ';
  }

}
