import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {StudiosService} from '../../studios.service';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {BookingsService} from '../../../bookings/bookings.service';
import {ActivatedRoute, Router} from '@angular/router';
import { ProfileService } from "../../../common/profile.service";
import {environment} from '../../../../environments/environment';
import { HelperService } from "../../../common/helper.service";
import {ConfirmationService, MessageService} from 'primeng/api';
import {InstrumentAndEquipmentService} from '../../../common/instruments.equipments.service';
import {Subject} from "rxjs";
import {NotificationsService} from "../../../notifications/notifications.service";


@Component({
  selector: 'app-studio-bookings',
  templateUrl: './studio-bookings.component.html',
  styleUrls: ['./studio-bookings.component.scss'],
  providers: [ConfirmationService]
})
export class StudioBookingsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() studioId: string;

  onDestroy$: Subject<void> = new Subject();
  bookings: any;
  bookingsSummaries: any;
  fetchNewBookings: any;
  loading = true;

  totalResults: number = 0; // Total number of bookings
  dataViewRows: number = 10; // Rows per page
  dataViewFirst: number = 0;

  public selectedBookingType = 'pending-confirmation';

  public bookingTypes = [];

  public env = environment;

  public studioLogo;

  public today = new Date().toISOString();
  public timeOfDay = new Date().toLocaleString('en-US', { hour: 'numeric', hour12: false })

  public isMobile = false;
  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private studiosService: StudiosService,
    private bookingsService: BookingsService,
    private instrumentAndEquipmentService: InstrumentAndEquipmentService,
    private profileService: ProfileService,
    private helperService: HelperService,
    private notificationsService: NotificationsService,
  ) {
    this.studioLogo = this.profileService.getOwnerStudio().media.find((media) => media.metadata.type === 'logo');
    this.isMobile = this.helperService.isMobile();
    this.mapBookingTypes();
  }

  ngOnInit(): void {
    this.setupObservables();

    if (this.profileService.isStudio()) {
      this.studioId = this.profileService.getOwnerStudio().id;
      this.fetchBookingsSummaries();
      this.fetchPartialData();
    }
  }

  setupObservables() {
    this.bookings = this.studiosService.bookings$.pipe(
      takeUntil(this.onDestroy$),
      filter(bookings => bookings && bookings.results),
      map((bookings) => {
        this.totalResults = bookings.totalResults;
        this.dataViewFirst = (bookings.page - 1) * this.dataViewRows;

        const results = bookings.results;
        results.forEach((booking) => {
          booking.needsConfirmation = false;
          if (booking.status === 'pending-confirmation') {
            booking.needsConfirmation = true;
          }
          booking.details = false;

          booking.instrumentsLabels = booking.instruments?.map(inst => inst.equipment?.name).join(', ');

          if (booking.members?.length > 0){
            booking.primaryMusicRoles = booking.members
              .map(member => booking.team.members.find(mbr => mbr.user.id === member.member.id))
              .map(member => member?.user?.musicRoles.find(role => role._id === member.musicRole))
              .map(mr => mr?.type ? [this.instrumentAndEquipmentService.getMusicRolesMap().get(mr.type)?.label] : [])
              .filter((roles) => roles.length > 0)
          }else {
            booking.primaryMusicRoles = booking.booker?.musicRoles.filter(role => role.primary).map(mr => this.instrumentAndEquipmentService.getMusicRolesMap().get(mr.type)?.label).filter((roles) => roles.length > 0)
          }
        });
        return results;
      }));

    this.bookingsSummaries = this.studiosService.bookingsByStatus$.pipe(
      takeUntil(this.onDestroy$),
      filter(bookingsByStatus => Object.keys(bookingsByStatus).length !== 0),
      map(bookingsByStatus => {
        this.bookingTypes.map((bookingType) => {bookingType.amount = 0; } );
        bookingsByStatus.forEach((status: { _id: any; count: any; }) => {
          switch (status._id) {
            case 'pending-confirmation':
            case 'planning':
              this.bookingTypes.find(bookingType => bookingType.code === 'pending-confirmation').amount += status.count;
              break;
            case 'studio-declined':
              this.bookingTypes.find((bookingType) => bookingType.code === 'rejected-bookings').amount += status.count;
              break;
            case 'studio-canceled':
              this.bookingTypes.find((bookingType) => bookingType.code === 'studio-canceled').amount += status.count;
              break;
            case 'user-declined':
            case 'user-canceled':
            case 'user-delete-decline':
            case 'reported':
              this.bookingTypes.find((bookingType) => bookingType.code === 'user-declined').amount += status.count;
              break;
            case 'completed-bookings':
              this.bookingTypes.find((bookingType) => bookingType.code === 'completed-bookings').amount += status.count;
              break;
            case 'upcoming-bookings':
              this.bookingTypes.find((bookType) => bookType.code === 'upcoming-bookings').amount += status.count;
              break;
            default:
              console.error(`Unknown booking type ${status._id} with count ${status.count}`);
          }
        });
        return bookingsByStatus;
      }));

    this.fetchNewBookings = this.notificationsService.fetchNewBookings$.pipe(
      takeUntil(this.onDestroy$),
      map(fetchNew => {
        if (fetchNew) {
          this.fetchPartialData();
        }
      }));
  }

  mapBookingTypes() {
    this.bookingTypes = [
      {name: 'Pending Requests', code: 'pending-confirmation' , amount : 0 , icon : 'pending', info: 'Your unanswered requests'},
      {name: 'Upcoming Bookings', code: 'upcoming-bookings' , amount : 0 , icon : 'event', info: 'Your accepted future bookings'},
      {name: 'Completed Bookings', code: 'completed-bookings' , amount : 0 , icon : 'event_available', info: 'Your accepted past bookings'},
      {name: 'Rejected Requests', code: 'rejected-bookings' , amount : 0 , icon : 'event_busy', info: 'Rejected user requests by you'},
      {name: 'User Canceled Bookings', code: 'user-declined' , amount : 0 , icon : 'event_busy', info: 'Accepted bookings cancelled by users'},
      {name: 'Studio Canceled Bookings', code: 'studio-canceled' , amount : 0 , icon : 'event_busy', info: 'Accepted bookings cancelled by studio'}
    ];
  }

  onBookingTypeSelection(selectedBookingType) {
    this.dataViewFirst = 0;
    this.selectedBookingType = selectedBookingType;
    this.fetchPartialData();
  }

  fetchData(view?: string) {
    this.loading = true;
    console.log('todo-fetchData/should be deprecated');
    this.studiosService.fetchStudioBookings(this.studioId, null, null, view)
      .then((bookings) => {
        this.loading = false;
      });
  }

  fetchBookingsSummaries() {
    this.loading = true;
    this.studiosService.fetchStudioBookingsSummaries(this.studioId)
      .then((bookings) => {
        this.loading = false;
      });
  }

  fetchPartialData() {
    this.loading = true;
    const page = this.dataViewFirst / this.dataViewRows + 1;
    const bookingStatusMap = {
      'pending-confirmation': 'pending-confirmation,planning',
      'upcoming-bookings': 'studio-confirmed',
      'completed-bookings': 'studio-confirmed',
      'rejected-bookings': 'studio-declined',
      'user-declined': 'user-declined,user-canceled,user-delete-decline,reported',
      'studio-canceled': 'studio-canceled',
    };
    const status = bookingStatusMap[this.selectedBookingType] || null;
    this.studiosService.fetchStudioBookingsByStatus(
      this.studioId,
      status,
      this.selectedBookingType,
      page,
      this.dataViewRows,
      this.profileService.getOwnerStudio())
    .then(data => {
      this.totalResults = data.totalResults;
      this.dataViewFirst = (page - 1) * this.dataViewRows;
      }
    ).finally(() => this.loading = false);
  }

  onPageChange(event: any): void {
    this.dataViewFirst = event.first;
    this.dataViewRows = event.rows;
    this.fetchPartialData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.studioId = changes.studioId.currentValue;
    if (changes.hasOwnProperty('studioId') && changes.studioId.currentValue) {
      this.fetchData();
    }
  }

  declineBooking(booking): void {
    if (booking.status === 'studio-confirmed') {
      this.confirmationService.confirm({
        message: 'Are you sure you want to cancel this upcoming booking?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          return this.confirmBooking(booking, false);
        }
      });
    } else {
      return this.confirmBooking(booking, false);
    }
  }

  confirmBooking(booking, accept = true , force = false): void {
    let newStatus = '';
    if (booking.status === 'pending-confirmation' && accept) {
      newStatus = 'studio-confirmed';
    } else if (booking.status === 'pending-confirmation' && !accept) {
      newStatus = 'studio-declined';
    } else if (booking.status === 'studio-confirmed' && !accept) {
      newStatus = 'studio-canceled';
    } else if (accept) {
      newStatus = 'studio-confirmed';
    }

    if (newStatus === 'studio-declined') {
      this.confirmationService.confirm({
        message: 'Are you sure you want to reject this booking request?',
        header: 'Confirm Booking Rejection',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.bookingsService.editBooking(booking.id , { status : newStatus })
            .then((data) => {
                this.messageService.add({
                  key: 'globalToast',severity: 'success', summary: 'Success', detail: 'You have declined this booking', life: 3000});

              this.studiosService.fetchStudioBookings(this.studioId)
            });
        }
      });
    } else {
      this.bookingsService.editBooking(booking.id , { status : newStatus, force })
        .then((data) => {
          if (newStatus === 'studio-canceled') {
            this.messageService.add({
              key: 'globalToast',severity: 'success', summary: 'Success', detail: 'You have canceled this booking', life: 3000});
          }
          this.studiosService.fetchStudioBookings(this.studioId)
        })
          .catch((err) => {
            if (err.error.message === "Booking slot is allready taken") {
              this.confirmationService.confirm({
                message: 'You have already accepted a conflicting booking request, are you sure you want to continue?',
                header: 'Confirm',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                  return this.confirmBooking(booking, accept , true);
                }
              });
            }
            this.messageService.add({
              key: 'globalToast', severity: 'error', summary: 'Error', detail: err.error.message, life: 3000});
          });
    }

  }

  reportBooking(booking) : void {
    if (
      booking.status === 'studio-confirmed' &&
      !(this.helperService.isAfter(new Date(booking.bookingDate))) &&
      !booking.reported
    ) {

      this.confirmationService.confirm({
        message: 'Are you sure you want to report No-Show from the customer?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.bookingsService.editBooking(booking.id , { reported : true })
            .then((data) => {
              this.messageService.add({
                key: 'globalToast',severity: 'success', summary: 'Success', detail: 'You have reported this booking', life: 3000});

              this.studiosService.fetchStudioBookings(this.studioId)
            });
        }
      });
    } else {
      this.messageService.add({severity: 'warn', summary: 'Warning', detail: 'This booking hasnt passed yet!', life: 3000});
    }
  }

  deleteBooking(booking): void {
    this.bookingsService.deleteBooking(booking.id)
      .then((data) => {
        this.messageService.add({
          key: 'globalToast',severity: 'success', summary: 'Success', detail: 'You have removed this Unavailable time', life: 3000});

        this.studiosService.fetchStudioBookings(this.studioId)
      });
  }

  seeBooker(booking) {
    if (booking.bookingType === 'solo') {
      this.router.navigate(['dashboard/users' , booking.booker.id]);
    } else {
      this.router.navigate(['dashboard/bands' , booking.team.id]);
    }
  }

  viewDetails(booking) {
    this.router.navigate(
      ['../', 'bookings', booking.id],
      {relativeTo: this.route.parent}
    );
  }

  showOnCalendar(booking) {
    this.router.navigate( ['calendar'], { relativeTo: this.route.parent.parent , queryParams : {initialDate : booking.bookingDate} });
  }

  getCurrency( booking, symbol = true ) {
    if (symbol) {
      return this.helperService.getCurrencySymbol(booking.priceCurrency ? booking.priceCurrency : 'EUR')
    } else {
      return booking.priceCurrency ? booking.priceCurrency : 'EUR';
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.studiosService.resetStudioBookings();
  }

}
