import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-google-redirect',
  templateUrl: './google-redirect.component.html',
  styleUrls: ['./google-redirect.component.scss']
})
export class GoogleRedirectComponent {
  constructor(private router: Router) {
    this.router.navigate(['/dashboard/partner/calendar'], {
      queryParams: {'setup-calendar-sync': true }
    });
  }
}
