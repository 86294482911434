import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HelperService} from '../common/helper.service';

@Injectable({
  providedIn: 'root'
})
export class BookingsService {

  private bookings: BehaviorSubject<any>;
  public readonly bookings$: Observable<any>;

  private booking: BehaviorSubject<any>;
  public readonly booking$: Observable<any>;

  private bookingMembers: BehaviorSubject<any>;
  public readonly bookingMembers$: Observable<any>;

  private notes: BehaviorSubject<any>;
  public readonly notes$: Observable<any>;

  private reviews: BehaviorSubject<any>;
  public readonly reviews$: Observable<any>;

  private errors: BehaviorSubject<any>;
  public readonly errors$: Observable<any>;

  private dataStore: {
    newBooking: any,
    booking: any,
    bookings: any[],
    bookingMembers: any[],
    notes: any[],
    reviews: any[]
  };


  constructor( private helperService: HelperService ) {

    this.booking = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.booking$ = this.booking.asObservable();

    this.bookings = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.bookings$ = this.bookings.asObservable();

    this.bookingMembers = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.bookingMembers$ = this.bookingMembers.asObservable();

    this.notes = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.notes$ = this.notes.asObservable();

    this.reviews = new BehaviorSubject([]) as BehaviorSubject<any>;
    this.reviews$ = this.reviews.asObservable();

    this.errors = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.errors$ = this.errors.asObservable();

    this.dataStore = {
      newBooking: {},
      bookings: [],
      booking: {},
      bookingMembers:  [],
      notes: [],
      reviews: []
    };

  }

  getNewBookingData() {
    return this.dataStore.newBooking;
  }
  resetNewBookingData() {
    this.dataStore.newBooking = {};
  }

  setNewBookingData( bookingData: any , step: string ) {
    this.dataStore.newBooking[step] = {};
    Object.assign(this.dataStore.newBooking[step] , bookingData);
    return this.dataStore.newBooking;
  }

  restartNewBookingData( bookingId: string ) {

    return this.helperService.getAction('/bookings/' + bookingId )
      .toPromise()
      .then((data) => {

        const resetData: any = {
          studio : {},
          type : {},
          info : {}
        };
        resetData.studio = {
          selectedStudio : {},
          selectedService: {},
          selectedRoom: {}
        };
        resetData.studio.selectedStudio.studio = data.studio;
        resetData.studio.selectedStudio.name = data.studio.label;
        resetData.studio.selectedStudio.id = data.studio.id;

        if (data.hasOwnProperty('service') && data.service ) {
          const foundService = data.studio.services.find((service) => service.id === data.service);
          resetData.studio.selectedService.service = foundService;
          resetData.studio.selectedService.name = foundService.name;
          resetData.studio.selectedService.id = foundService.id;
        }

        if (data.hasOwnProperty('room') && data.room) {
          const foundRoom = data.studio.rooms.find((room) => room.id === data.room);
          resetData.studio.selectedRoom.room = foundRoom;
          resetData.studio.selectedRoom.name = foundRoom.name;
          resetData.studio.selectedRoom.id = foundRoom.id;
        }


        resetData.type = {
          bookingType : '',
          selectedBand : {},
          selectedMembers: []
        };

        resetData.type.bookingType = 'band';
        resetData.type.selectedBand.band = data.team;
        resetData.type.selectedBand.name = data.team.name;
        resetData.type.selectedBand.id = data.team.id;
        resetData.type.selectedMembers =  [ ...data.members.map(memberObj => memberObj.member)];

        resetData.info = {
          bookDate: data.bookingDate,
          bookLabel: data.label,
          bookDuration: data.duration,
          bookVoting: true
        };
        this.dataStore.newBooking = resetData;
        this.dataStore.newBooking.restartBookingId = bookingId;
        return this.dataStore.newBooking;
      });
  }


  createBooking( bookingData: any ) {
    return this.helperService.postAction('/bookings/', bookingData)
      .toPromise()
      .then((data) => {
        this.dataStore.bookings.push(data);
        this.bookings.next(this.dataStore.bookings);
        return data;
      });
  }

  restartBooking( bookingId: string, bookingData: any ) {
    return this.helperService.postAction('/bookings/' + bookingId + '/restart', bookingData)
      .toPromise()
      .then((data) => {
        this.dataStore.booking = Object.assign({}, data);
        this.booking.next(this.dataStore.booking);
        return data;
      });
  }

  fetchBookings() {
    return this.helperService.getAction('/bookings' , {limit : 1000} )
      .toPromise()
      .then((data) => {
        data.results.forEach((booking) => {
          this.mapInternalData(booking);
        });
        this.dataStore.bookings = [...data.results];
        this.bookings.next(this.dataStore.bookings);
        return data;
      });
  }

  fetchBooking( bookingId: string ) {
    return this.helperService.getAction('/bookings/' + bookingId )
      .toPromise()
      .then((data) => {
        this.mapInternalData(data);
        this.dataStore.booking = data;
        this.booking.next(this.dataStore.booking);
        return data;
      });
  }

  editBooking( bookingId: string, bookingData: any) {
    return this.helperService.patchAction('/bookings/' + bookingId , bookingData )
      .toPromise()
      .then((data) => {
        this.mapInternalData(data);
        Object.assign(this.dataStore.booking, data);
        this.booking.next(this.dataStore.booking);
        return data;
      });
  }

  deleteBooking( bookingId: string ) {
    return this.helperService.deleteAction('/bookings/' + bookingId )
      .toPromise()
      .then((data) => {
        this.dataStore.bookings = this.dataStore.bookings.filter(bk => bk.id !== bookingId);
        this.bookings.next(this.dataStore.bookings);
        return data;
      });
  }

  fetchBookingMembers( bookingId: string) {
    return this.helperService.getAction('/bookings/' + bookingId + '/members' )
      .toPromise()
      .then((data) => {
        this.dataStore.bookingMembers = [...data];
        this.bookingMembers.next(this.dataStore.bookingMembers);
        return data;
      });
  }

  addBookingMembers( bookingId: string , memberId: string) {
    return this.helperService.patchAction('/bookings/' + bookingId + '/members' )
      .toPromise()
      .then((data) => {
        this.dataStore.bookingMembers = [...data.members];
        this.bookingMembers.next(this.dataStore.bookingMembers);
        return data;
      });
  }

  removeBookingMembers( bookingId: string , memberId: string) {
    return this.helperService.deleteAction('/bookings/' + bookingId + '/members/' + memberId )
      .toPromise()
      .then((data) => {
        this.dataStore.bookingMembers = this.dataStore.bookingMembers.filter(mbr => mbr.id !== memberId);
        this.bookingMembers.next(this.dataStore.bookingMembers);
        return data;
      });
  }

  acceptMemberBooking( bookingId: string ) {
    return this.helperService.postAction('/bookings/' + bookingId + '/members/accept' )
      .toPromise()
      .then((data) => {
        this.dataStore.booking = data;
        this.booking.next(this.dataStore.booking);
        return data;
      });
  }

  declineMemberBooking( bookingId: string ) {
    return this.helperService.postAction('/bookings/' + bookingId + '/members/decline' )
      .toPromise()
      .then((data) => {
        this.dataStore.booking = data;
        this.booking.next(this.dataStore.booking);
        return data;
      });
  }

  fetchNotes( bookingId: string) {
    return this.helperService.getAction('/bookings/' + bookingId + '/notes' )
      .toPromise()
      .then((data) => {
        this.dataStore.notes = [...data];
        this.notes.next(data);
        return data;
      });
  }

  addNote( bookingId: string , note: any) {
    return this.helperService.postAction('/bookings/' + bookingId + '/notes' , note )
      .toPromise()
      .then((data) => {
        this.dataStore.notes = [...data.notes];
        this.notes.next(data);
        return data;
      });
  }

  getReviews( bookingId: string, populate = true) {
    return this.helperService.getAction('/bookings/' + bookingId + '/reviews' )
      .toPromise()
      .then((data) => {
        this.dataStore.reviews = [...data.results];
        this.reviews.next(this.dataStore.reviews);
        return data;
      });
  }

  addReview( bookingId: string, review: any) {
    return this.helperService.postAction('/bookings/' + bookingId + '/reviews' , review )
      .toPromise()
      .then((data) => {
        this.dataStore.reviews = [...this.dataStore.reviews , data];
        this.reviews.next(this.dataStore.reviews);
        return data;
      });
  }

  addReply( reviewId: string, response: any) {
    return this.helperService.postAction('/reviews/' + reviewId + '/response' , response )
      .toPromise()
      .then((data) => {
        this.dataStore.reviews = [...this.dataStore.reviews , data];
        this.reviews.next(this.dataStore.reviews);
        return data;
      });
  }

  mapInternalData( booking: any) {
    if (typeof booking.studio !== 'string') {
      if (booking.hasOwnProperty('room') && typeof booking.room === 'string') {
        booking.room = booking.studio.rooms.find((room) => room.id === booking.room);
      }
      if (booking.hasOwnProperty('service') && typeof booking.service === 'string') {
        booking.service = booking.room.services.find((service) => service.id === booking.service);
      }
      if (booking.hasOwnProperty('discount') && typeof booking.discount === 'string') {
        booking.discount = booking.service.discounts.find((discount) => discount.id === booking.discount);
      }
      if (booking.hasOwnProperty('instruments') && booking.instruments.length > 0) {
        booking.instruments = booking.instruments.map((instrument) => {
          return booking.studio.instruments.find((inst) => inst.id === instrument);
        });
      }
    }
  }

}
