<p-card [style]="{borderRadius : 4}"
        header="Booking Overview">
  <p>Studio Booking ({{booking.service?.type}}) <span style="float:right;">
        {{servicePrice}}{{getCurrency()}}
    </span></p>

  <p *ngIf="bookingDiscount" style="font-size: 0.8rem; font-style: italic;">Applied Discount ({{bookingDiscount.name | titlecase}}) <span style="font-style:initial; float:right;">
        - {{bookingDiscount.rules.percentage}}%
    </span></p>


  <p-divider></p-divider>
  <p>Instrument Rental <span style="float:right;">
        {{rentalPrice}}{{getCurrency()}}
    </span></p>
  <p-divider></p-divider>

  <ng-template
    [ngIf]="env.promoCodesEnabled && promoCode"
  >
    <p>
      <span style="font-style: italic;">{{promoCode.name}}</span>
      <span style="float:right"> - {{promoCode.discount}} {{promoCode.category === 'amount' ? getCurrency() : '%' }}</span>
    </p>
  </ng-template>

  <p><strong>Total Cost {{recurringDates.length > 1 ? ' (per day) ' : ''}}<span style="float:right;">

    <ng-template
      [ngIf]="recurringDates.length < 1 || !(promoCode && promoCode.advancedRules.onceOnRecurring)"
    >{{Math.round( totalPrice * 100 ) / 100 }}{{getCurrency()}}
    </ng-template>
    <ng-template
      [ngIf]="recurringDates.length > 1 && promoCode && promoCode.advancedRules.onceOnRecurring"
    >{{ Math.round( 100 * ( ( totalPrice * recurringDates.length + promoCode.discount * ( recurringDates.length - 1 ) ) / recurringDates.length ) ) / 100 }}{{getCurrency()}}
    </ng-template>

    </span></strong></p>

  <p
    *ngIf="recurringDates.length > 1"
  ><strong>Total Cost for {{recurringDates.length}} days<span style="float:right;">

        <ng-template
          [ngIf]="promoCode && promoCode.advancedRules.onceOnRecurring"
        >{{ Math.round( 100 * ( totalPrice * recurringDates.length + promoCode.discount * ( recurringDates.length - 1 ) ) ) / 100 }}{{getCurrency()}}
        </ng-template>
        <ng-template
          [ngIf]="!(promoCode && promoCode.advancedRules.onceOnRecurring)"
        >{{Math.round(totalPrice * recurringDates.length * 100 ) / 100}}{{getCurrency()}}
        </ng-template>

    </span></strong></p>


  <p-divider></p-divider>
  <p >
    <strong *ngIf="
    booking.paymentMethod === 'online' &&
    booking.paymentDone === true &&
    booking.paymentStatus &&
    booking.paymentStatus === 'CANCELED'
    ">
      Payment Status<span class="keyword" style="float:right;">Canceled</span>
    </strong>
    <strong *ngIf="
    booking.paymentMethod === 'online' &&
    booking.paymentDone === true &&
    booking.paymentStatus &&
    booking.paymentStatus === 'CAPTURED'
    ">
      Payment Status<span
      class="keyword"
      style="float:right;"
      [pTooltip]="'User has paid online'"
    >Paid Online</span>
    </strong>
    <strong *ngIf="
    booking.paymentMethod === 'online' &&
    booking.paymentStatus &&
    booking.paymentStatus === 'AUTHORIZED'
    ">
      Payment Status<span
      class="keyword"
      style="float:right;"
      [pTooltip]="'User has paid online'"
    >Paid Online</span>
    </strong>
    <strong *ngIf="
    booking.paymentMethod === 'online' &&
    booking.paymentDone === true &&
    booking.paymentStatus &&
    booking.paymentStatus === 'REFUNDED'
    ">
      Payment Status<span class="keyword" style="float:right;">Refunded</span>
    </strong>
    <strong *ngIf="booking.paymentMethod === 'offline' || booking.paymentDone !== true">
      Payment Status<span class="" style="float:right;">Cash</span>
    </strong>

  </p>
  <ng-template pTemplate="footer">
    <div *ngIf="needResponseAction">
      <p-button (onClick)="confirmBooking(booking, true)" [style]="{width: '100%'}" class="p-button-rounded p-button-lg "
                label="Accept Request"></p-button>
      <div style="text-align:center">
        <button class="p-button-l p-button-text" label="Decline Request" pButton pRipple
                type="button" style="color:#F2B8B5" (click)="confirmBooking(booking, false)"></button>
      </div>
    </div>

    <div *ngIf="needReviewAction">
      <p-button
        (onClick)="showAddReview()"
        [style]="{width: '100%'}"
        class="p-button-rounded p-button-lg "
        label="Add Review"
      ></p-button>

    </div>

    <div *ngIf="needReplyAction">
      <p-button
        (onClick)="showAddReply()"
        [style]="{width: '100%'}"
        styleClass="secondary-button"
        label="Reply to Review"
      ></p-button>

    </div>
  </ng-template>
</p-card>



<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
